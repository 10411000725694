export const isAppAdmin = function (host = window.location.host) {
  const subdomain = host.split('.')[0]

  const domainsAdmin = [
    process.iblsEnv.IBLS_ADMIN_DOMAIN,
    process.iblsEnv.IBLS_E_SECRETARIAT_DOMAIN,
    process.iblsEnv.IBLS_E_LIBRARY_DOMAIN,
  ]

  return (
    subdomain === process.iblsEnv.IBLS_ADMIN_SUBDOMAIN ||
    domainsAdmin.includes(host)
  )
}
