import { ApiCustomerReceipt } from 'ApiRest/Api/Account/Client/Invoice/CustomerReceipt'
import { ApiActionLog } from 'ApiRest/Api/Account/Client/Request/ActionLog'
import { ApiStudentFeOffer } from 'ApiRest/Api/Account/Student/FamilyEducationOfferForSchool'
import { ApiDemoFeSimpleRequestCall } from 'ApiRest/Api/Auth/DemoFeSimple/RequestCall'
import { ApiDemoFeSimpleRequestForm } from 'ApiRest/Api/Auth/DemoFeSimple/RequestForm'
import { ApiFeServiceCheckPromocode } from 'ApiRest/Api/Auth/Fe/ServiceCheckPromocode'
import { ApiFeServiceDetails } from 'ApiRest/Api/Auth/Fe/ServiceDetails'
import { ApiStatusWebhook } from 'ApiRest/Api/Enrollment/Request/StatusWebhook'
import mammoth from 'mammoth/mammoth.browser'

export default {
  async fetchForm({ commit }) {
    const { data } = await ApiDemoFeSimpleRequestForm.get()

    commit('setForm', data)
  },

  async fetchInvoice({ state, commit, dispatch }) {
    const { data } = await ApiDemoFeSimpleRequestForm.post({
      ...state.form,
      acceptOffer: Number(state.isAccepted),
      paymentType: state.paymentType.id,
    })

    commit('setInvoice', data)

    await dispatch('sendStatusWebhook')
    await dispatch('actionLog')
  },

  async fetchCustomerReceipt({ state, commit }) {
    const { data } = await ApiCustomerReceipt.get(state.invoice.id)

    commit('setCustomerReceipt', data)
  },

  async fetchPromocodeDetails({ state, commit }) {
    const { data } = await ApiFeServiceCheckPromocode.post({
      code: state.form.promocode,
    })

    commit('setPromocodeDetails', data)
  },

  async fetchOfferText({ rootState, commit }) {
    const schoolId = rootState.student.profile.profile.school.id
    const { data } = await ApiStudentFeOffer.get(schoolId)

    const result = await mammoth.convertToHtml({
      arrayBuffer: data,
    })

    commit('setOfferText', result.value)
  },

  async fetchServiceDetails({ commit }) {
    const { data } = await ApiFeServiceDetails.get()

    commit('setServiceDetails', data)
  },

  async requestCall({ state }) {
    await ApiDemoFeSimpleRequestCall.put({
      source: 'payment',
      grade: state.form?.grade?.id,
    })
  },

  async sendStatusWebhook({ getters }) {
    try {
      await ApiStatusWebhook.post(getters.statusData)
    } catch {}
  },

  async actionLog({ state }) {
    try {
      await ApiActionLog.post({
        actionTypeId: 13,
        entityId: state.invoice?.id,
      })
    } catch {}
  },
}
