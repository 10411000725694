var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { class: _vm.$style.slider },
    [
      _c(
        "div",
        { ref: "slider", staticClass: "keen-slider" },
        _vm._l(_vm.slides, function (slide, i) {
          return _c(
            _vm.slideComponent(slide),
            _vm._b(
              { key: i, tag: "component", staticClass: "keen-slider__slide" },
              "component",
              _vm.slideComponentAttrs(slide),
              false
            ),
            [
              _c(
                "figure",
                {
                  staticClass: "figure mb-0",
                  on: {
                    click: function ($event) {
                      return _vm.onClickSlide(slide)
                    },
                  },
                },
                [
                  _c("ElImage", {
                    staticClass: "figure-img mb-0",
                    class: _vm.cssClassImage,
                    attrs: {
                      "image-id": slide.image.id,
                      src: slide.image.url,
                      alt: slide.alt,
                      breakpoints: _vm.breakpoints,
                    },
                  }),
                ],
                1
              ),
            ]
          )
        }),
        1
      ),
      _vm._v(" "),
      _vm.isRendered.controls
        ? _c("Controls", {
            staticClass: "mt-1 mt-lg-4",
            attrs: {
              "slide-index-current": _vm.slideIndexCurrent,
              "slides-count": _vm.slidesCount,
            },
            on: {
              "click:dot": _vm.onClickDot,
              "click:prev": _vm.onClickPrev,
              "click:next": _vm.onClickNext,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }