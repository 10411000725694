<template>
  <ElModal
    :name="name"
    :width="600"
    :adaptive="true"
    :click-to-close="false"
    transition="none"
    class="modal-content"
    @before-open="onBeforeOpen"
  >
    <ContentWithPreloader :is-loading="isLoading.form">
      <!-- TODO: i18n -->
      <h2 class="h2">Успешная оплата, укажите данные</h2>

      <!-- TODO: i18n -->
      <div class="mb-3 text-gray">
        Для обучения необходимо подтвердить данные
      </div>

      <section>
        <div class="mb-3">
          <!-- TODO: i18n -->
          <h3 class="h3">Данные клиента</h3>

          <div class="row">
            <div class="col-lg-6">
              <!-- TODO: i18n -->
              <ElInput
                v-model="$v.clientLastName.$model"
                :invalid="$v.clientLastName.$error"
                label="Фамилия"
                required
              />
            </div>

            <div class="col-lg-6">
              <!-- TODO: i18n -->
              <ElInput
                v-model="$v.clientFirstName.$model"
                :invalid="$v.clientFirstName.$error"
                label="Имя"
                required
              />
            </div>

            <div class="col-lg-6">
              <!-- TODO: i18n -->
              <ElInput
                v-model="$v.clientMiddleName.$model"
                :invalid="$v.clientMiddleName.$error"
                label="Отчество"
                hint='Если нет, то введите "-"'
                required
              />
            </div>

            <div class="col-lg-6">
              <!-- TODO: i18n -->
              <ElInput
                v-model="$v.clientPhone.$model"
                :invalid="$v.clientPhone.$error"
                label="Телефон"
                required
              />
            </div>
          </div>
        </div>

        <!-- TODO: i18n -->
        <div
          v-if="isRendered.clientCredentialsMessage"
          class="mb-3 text-pink font-size-16"
        >
          Данные для входа в личный кабинет клиента отправлены на электронную
          почту
        </div>

        <div class="mb-3">
          <!-- TODO: i18n -->
          <h3 class="h3">Данные ученика</h3>

          <div class="row">
            <div class="col-lg-6">
              <!-- TODO: i18n -->
              <ElInput
                v-model="$v.studentLastName.$model"
                :invalid="$v.studentLastName.$error"
                label="Фамилия"
                required
              />
            </div>

            <div class="col-lg-6">
              <!-- TODO: i18n -->
              <ElInput
                v-model="$v.studentFirstName.$model"
                :invalid="$v.studentFirstName.$error"
                label="Имя"
                required
              />
            </div>

            <div class="col-lg-6">
              <!-- TODO: i18n -->
              <ElInput
                v-model="$v.studentMiddleName.$model"
                :invalid="$v.studentMiddleName.$error"
                label="Отчество"
                hint='Если нет, то введите "-"'
                required
              />
            </div>

            <div class="col-lg-6">
              <!-- TODO: i18n -->
              <ElDatePicker
                v-model="$v.studentBirthDate.$model"
                :invalid="$v.studentBirthDate.$error"
                label="Дата рождения"
                required
              />
            </div>
          </div>
        </div>
      </section>

      <!-- TODO: i18n -->
      <ElButton
        :disabled="isDisabled.btnSave"
        class="btn-primary"
        label="Сохранить"
        @click="onClickBtnSave"
      />
    </ContentWithPreloader>
  </ElModal>
</template>

<script>
import ContentWithPreloader from '@frontend/ui/ContentWithPreloader.vue'
import ElButton from '@frontend/ui/ElButton.vue'
import ElDatePicker from '@frontend/ui/ElDatePicker.vue'
import ElInput from '@frontend/ui/ElInput.vue'
import ElModal from '@frontend/ui/ElModal.vue'
import dateFormat from 'Helpers/const/dateFormat'
import { getDate } from 'Helpers/dateFormatHelper'
import { showErrors, showToast } from 'Helpers/toast'
import {
  validateDate,
  validateName,
  validatePhone,
} from 'Helpers/ValidationHelper'
import { required } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'

export default {
  name: 'ModalProfileUpdate',

  components: {
    ContentWithPreloader,
    ElInput,
    ElDatePicker,
    ElModal,
    ElButton,
  },

  props: {
    student: {
      type: Object,
      default: null,
    },

    form: {
      type: Object,
      default: null,
    },

    setFormProp: {
      type: Function,
      default: () => {},
    },

    fetchForm: {
      type: Function,
      default: () => {},
    },

    updateForm: {
      type: Function,
      default: () => {},
    },
  },

  data() {
    return {
      name: 'modalProfileUpdate',

      isLoading: {
        form: false,
        btnSave: false,
      },
    }
  },

  computed: {
    ...mapGetters('common/user', [
      'isStudent',
    ]),

    isRendered() {
      return {
        clientCredentialsMessage: this.isStudent,
      }
    },

    isDisabled() {
      return {
        btnSave: this.$v.$invalid,
      }
    },

    /**
     * @public
     */
    clientLastName: {
      get() {
        return this.form?.clientLastName ?? ''
      },

      set(value) {
        this.setFormProp({
          key: 'clientLastName',
          value,
        })
      },
    },

    /**
     * @public
     */
    clientFirstName: {
      get() {
        return this.form?.clientFirstName ?? ''
      },

      set(value) {
        this.setFormProp({
          key: 'clientFirstName',
          value,
        })
      },
    },

    /**
     * @public
     */
    clientMiddleName: {
      get() {
        return this.form?.clientMiddleName ?? ''
      },

      set(value) {
        this.setFormProp({
          key: 'clientMiddleName',
          value,
        })
      },
    },

    /**
     * @public
     */
    clientPhone: {
      get() {
        return this.form?.clientPhone ?? ''
      },

      set(value) {
        this.setFormProp({
          key: 'clientPhone',
          value,
        })
      },
    },

    /**
     * @public
     */
    studentLastName: {
      get() {
        return this.form?.studentLastName ?? ''
      },

      set(value) {
        this.setFormProp({
          key: 'studentLastName',
          value,
        })
      },
    },

    /**
     * @public
     */
    studentFirstName: {
      get() {
        return this.form?.studentFirstName ?? ''
      },

      set(value) {
        this.setFormProp({
          key: 'studentFirstName',
          value,
        })
      },
    },

    /**
     * @public
     */
    studentMiddleName: {
      get() {
        return this.form?.studentMiddleName ?? ''
      },

      set(value) {
        this.setFormProp({
          key: 'studentMiddleName',
          value,
        })
      },
    },

    /**
     * @public
     */
    studentBirthDate: {
      get() {
        return this.form?.studentBirthDate ?? null
      },

      set(value) {
        this.setFormProp({
          key: 'studentBirthDate',
          value: getDate(value, dateFormat.DATE_FORMAT_FRONTEND),
        })
      },
    },
  },

  validations: {
    clientLastName: {
      required,
      validateName,
    },

    clientFirstName: {
      required,
      validateName,
    },

    clientMiddleName: {
      validateName,
    },

    clientPhone: {
      required,
      validatePhone,
    },

    studentLastName: {
      required,
      validateName,
    },

    studentFirstName: {
      required,
      validateName,
    },

    studentMiddleName: {
      validateName,
    },

    studentBirthDate: {
      required,
      validateDate,
    },
  },

  created() {
    this.init()
  },

  methods: {
    async save() {
      try {
        await this.updateForm(this.student)

        // TODO: i18n
        showToast('Данные сохранены')

        this.$emit('success')
        this.hide()
      } catch (error) {
        showErrors(error)
      }
    },

    async onBeforeOpen() {
      this.isLoading.form = true

      try {
        await this.fetchForm(this.student)
      } catch (error) {
        showErrors(error)
      }

      this.isLoading.form = false
    },

    async onClickBtnSave() {
      this.isLoading.btnSave = true

      await this.save()

      this.isLoading.btnSave = false
    },

    init() {
      this.$v.$reset()
    },

    /**
     * @public
     */
    show() {
      this.$modal.show(this.name)
    },

    /**
     * @public
     */
    hide() {
      this.$modal.hide(this.name)
    },
  },
}
</script>
