import { isAfter } from 'date-fns'
import { numberToCurrency } from 'Helpers/numberToCurrency'
import { isNull } from 'lodash'

export default {
  hasVacantSeats(state) {
    return state.product?.vacantSeats > 0
  },

  hasUser(state) {
    return state.user !== null
  },

  dateStart(state) {
    if (state.product) {
      const { dateStart, timeStart } = state.product.holding
      const dateTime = [dateStart, timeStart].join('T')

      return new Date(dateTime)
    }

    return new Date()
  },

  dateServer(state) {
    return new Date(state.serverTimestamp * 1000)
  },

  isEnrollPaused(state, getters) {
    return isAfter(getters.dateServer, getters.dateStart)
  },

  hasPrice(state) {
    return !isNull(state.offer) && state.offer.price > 0
  },

  price(state, getters) {
    return getters.hasPrice ? numberToCurrency(state.offer.price) : 'Бесплатно'
  },

  dolyamePrice(state, getters) {
    if (getters.hasPrice) {
      return numberToCurrency(Math.ceil(state.offer.price / 4))
    }

    return ''
  },
}
