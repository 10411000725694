var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isRendered.externalUrl
    ? _c(
        "a",
        {
          staticClass: "s-aside__link",
          class: _vm.link.class,
          attrs: {
            target: _vm.link.isExternal ? "_blank" : false,
            href: _vm.link.externalUrl,
            rel: _vm.link.isExternal ? "noopener noreferrer" : false,
          },
        },
        [
          _c("span", [_vm._v(_vm._s(_vm.$t(_vm.link.labelPath)))]),
          _vm._v(" "),
          _vm.isRendered.notification
            ? _c("i", { staticClass: "notif" }, [
                _vm._v("\n    " + _vm._s(_vm.link.notifications) + "\n  "),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.isRendered.iconLinkExternal
            ? _c("img", {
                class: _vm.$style.iconLinkExternal,
                attrs: { src: _vm.icons.linkExternal, alt: "external link" },
              })
            : _vm._e(),
        ]
      )
    : _c(
        "router-link",
        {
          staticClass: "s-aside__link",
          class: _vm.link.class,
          attrs: { to: _vm.routerLink },
        },
        [
          _c("span", [_vm._v(_vm._s(_vm.$t(_vm.link.labelPath)))]),
          _vm._v(" "),
          _vm.isRendered.notification
            ? _c("i", { staticClass: "notif" }, [
                _vm._v("\n    " + _vm._s(_vm.link.notifications) + "\n  "),
              ])
            : _vm._e(),
        ]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }