// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ElButton__btn__mtDv:disabled {\n  pointer-events: none;\n}", "",{"version":3,"sources":["webpack://./webpack/@frontend/ui/ElButton.vue"],"names":[],"mappings":"AACA;EACE,oBAAA;AAAF","sourcesContent":["\n.btn:disabled {\n  pointer-events: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn": "ElButton__btn__mtDv"
};
export default ___CSS_LOADER_EXPORT___;
