/**
 * @param {string} text
 * @param {boolean} async
 */
export const loadScriptByText = function (text, async = true) {
  const firstScript = document.getElementsByTagName('script')[0]
  const script = document.createElement('script')

  script.text = text
  script.async = async

  firstScript.parentNode.insertBefore(script, firstScript)
}
