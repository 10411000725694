<template>
  <div class="form-group" :class="{ 'is-invalid': invalid }">
    <label class="form-label">
      {{ label }} <i v-if="required" class="text-pink">*</i>
    </label>

    <slot name="link"></slot>

    <div class="input-group">
      <input
        v-bind="$attrs"
        :value="value"
        :type="showPassword ? 'text' : 'password'"
        :disabled="disabled"
        name="password"
        class="form-control"
        @input="onInput"
      />

      <div class="input-group-append">
        <div
          class="btn btn--center btn--opacity d-flex align-items-center"
          :class="{ active: showPassword }"
          @click.prevent="onClickIconEye"
        >
          <img :src="icons.eye" alt="eye" />
        </div>
      </div>
    </div>

    <div class="form-invalid">
      <slot name="invalid" />
    </div>
  </div>
</template>

<script>
import iconEye from 'Static/images/@frontend/common-lk/icons/eye.svg'

export default {
  name: 'ElInputPassword',

  inheritAttrs: false,

  props: {
    value: {
      type: String,
      required: true,
    },

    // TODO: i18n
    invalid: {
      type: Boolean,
      required: true,
    },

    required: {
      type: Boolean,
    },

    disabled: {
      type: Boolean,
    },

    label: {
      type: String,
      default: 'Пароль',
    },
  },

  data() {
    return {
      showPassword: false,

      icons: {
        eye: iconEye,
      },
    }
  },

  methods: {
    onInput(e) {
      this.$emit('input', e.target.value)
    },

    onClickIconEye() {
      this.showPassword = !this.showPassword
    },
  },
}
</script>
