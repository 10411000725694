import ApiBase from 'ApiRest/ApiBase'

export const ApiShowcaseOnlineLessonJoin = new (class extends ApiBase {
  /**
   * Получить ссылку для входа в конференцию
   * @param {number} onlineLessonId
   * @returns {string}
   */
  get(onlineLessonId) {
    if (process.iblsEnv.IS_PRODUCTION) {
      return `https://api.iblschool.ru/rest/showcase/account/online-lessons/${onlineLessonId}/join`
    }

    return `${window.domains.apiUrl}/rest/showcase/account/online-lessons/${onlineLessonId}/join`
  }
})()
