import getErrorsList from '@frontend/helpers/getErrorsList'
import HttpStatusCodes from 'Api/const/HttpStatusCodes'
import ApiTheCertificationIsAvailable from 'ApiRest/Api/Account/Client/Students/FamilyEducationCertificationRequest/TheCertificationIsAvailable'
import ApiTheCertificationRequest from 'ApiRest/Api/Account/Client/Students/FamilyEducationCertificationRequest/TheCertificationRequest'
import ApiOption from 'ApiRest/Api/Option'
import Vue from 'vue'

import { i18n } from '@/i18n/setup'

const showError = (error) => {
  const message = error?.response?.data?.message

  if (message) {
    Vue.toasted.show(message, {
      type: 'error',
      duration: 5000,
    })
  } else {
    Vue.toasted.show(getErrorsList(error), {
      type: 'error',
      keepOnHover: true,
      closeOnSwipe: false,
      duration: Infinity,
      action: {
        text: i18n.t('store.client.toasted_message.close'),
        onClick: (e, toastObject) => {
          toastObject.goAway(0)
        },
      },
    })
  }
}

export default {
  resetState({ commit }) {
    commit('resetState')
  },

  async fetchLegalRepresentativeTypes({ commit }) {
    const { data } = await ApiOption.getLegalRepresentativeTypes()

    commit('setLegalRepresentativeTypes', data)
  },

  async fetchSubmissionTypes({ state, commit }) {
    if (!state.submissionTypes.length) {
      const { data } =
        await ApiOption.getFamilyEducationCertRequestSubmissionType()

      commit('setSubmissionTypes', data)
    }
  },

  async fetchCitizenships({ state, commit }) {
    if (!state.citizenships.length) {
      const { data } = await ApiOption.getCitizenships()

      commit('setCitizenships', data)
    }
  },

  async fetchCountries({ state, commit }) {
    if (!state.countries.length) {
      const { data } = await ApiOption.getCountries()

      commit('setCountries', data)
    }
  },

  async fetchTheFamilyCertificationRequestIsAvailable(
    { commit, dispatch },
    studentId,
  ) {
    const { data, status } = await ApiTheCertificationIsAvailable.get(studentId)

    if (status === HttpStatusCodes.Ok) {
      commit('setTheFamilyCertificationRequestIsAvailable', data)

      await dispatch('fetchTheFamilyCertificationRequest', studentId)
    }
  },

  async fetchTheFamilyCertificationRequest({ commit }, studentId) {
    const { data } = await ApiTheCertificationRequest.get(studentId)

    commit('setTheFamilyCertificationRequest', data)
  },

  async createTheFamilyCertificationRequest(
    { commit },
    { studentId, payload },
  ) {
    try {
      const { data } = await ApiTheCertificationRequest.post(studentId, payload)

      commit('setTheFamilyCertificationRequest', data)
    } catch (error) {
      showError(error)

      throw new Error(error)
    }
  },

  async updateTheFamilyCertificationRequest(
    { commit },
    { studentId, payload },
  ) {
    try {
      const { data } = await ApiTheCertificationRequest.patch(
        studentId,
        payload,
      )

      commit('setTheFamilyCertificationRequest', data)
    } catch (error) {
      showError(error)

      throw new Error(error)
    }
  },

  async deleteTheFamilyCertificationRequest(context, studentId) {
    await ApiTheCertificationRequest.delete(studentId)

    // TODO: -> component
    Vue.toasted.show(
      i18n.t('store.client.toasted_message.application_cancelled'),
      {
        type: 'success',
        duration: 5000,
      },
    )
  },

  async updateTheFamilyCertificationRequestDocInfo(
    { commit },
    { studentId, payload },
  ) {
    try {
      const { data } = await ApiTheCertificationRequest.put(studentId, payload)

      commit('setTheFamilyCertificationRequest', data)
    } catch (error) {
      showError(error)

      throw new Error(error)
    }
  },
}
