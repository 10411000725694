<template>
  <header ref="header" class="s-header-wrapper" :class="cssClass.headerWrapper">
    <div v-if="noticeText" class="s-header__notice">
      <span>{{ noticeText }}</span>
      <span class="notice-text--thin">{{ noticeAdditionalMessage }}</span>
    </div>

    <div class="s-header" :class="cssClass.header">
      <router-link class="s-logo s-header__logo" :to="routeToMain">
        <h2 class="s-school-name">{{ schoolName }}</h2>
        <hr class="s-school-divider" />
        <img
          class="s-school-logo"
          :src="logos.default"
          :alt="$t('layout.common.logotype')"
        />
      </router-link>

      <div class="s-header__profile d-lg-none d-xl-flex">
        <ElAvatar :abbreviation="abbreviation" :image="avatar" shape="circle" />

        <span class="text">
          <span class="name">{{ fullName }}</span>
        </span>
      </div>

      <div v-if="isRendered.studentSelect" class="s-header__student-select">
        <v-select
          :value="currentStudentFormatted"
          :options="studentsFormatted"
          :clearable="false"
          :searchable="false"
          label="abbr"
          class="v-select-student"
          @input="selectStudent"
        >
          <template #selected-option="{ abbr, name, gradeName, photo }">
            <div class="s-header__student">
              <ElAvatar :abbreviation="abbr" :image="getStudentAvatar(photo)" />

              <span class="text">
                <span class="name">{{ name }}</span>
                <span class="grade">{{ gradeName }}</span>
              </span>
            </div>
          </template>

          <template #option="{ name, gradeName }">
            <div class="s-header__student-option">
              <b class="name">{{ name }}</b>
              <span class="t-tag">{{ gradeName }}</span>
            </div>
          </template>
        </v-select>
      </div>

      <!-- TODO: i18n -->
      <ElButton
        v-if="isRendered.btnSwitchToStudent"
        :is-loading="isLoading.btnSwitchToStudent"
        label="Перейти в кабинет ученика"
        class="btn-outline-light d-none d-lg-block"
        @click="onClickBtnSwitchToStudent"
      />

      <TheHeaderRating v-if="isRendered.rating" :rating="rating" />

      <TheHeaderCredit v-if="isRendered.credit" :rating="credit" />

      <nav class="s-header__nav">
        <LanguageSelect
          v-if="isRendered.langSelect"
          v-model="lang"
          :options="langOptionsAvailable"
          class="v-select-language mr-2"
        />

        <router-link
          v-if="isRendered.routesTo.notifications"
          :to="linkNotifications.link"
        >
          <img :src="icons.bell" :alt="$t('layout.common.notification_icon')" />

          <i v-if="isRendered.notifications.notifications" class="notif">
            {{ linkNotifications.notifications }}
          </i>
        </router-link>

        <router-link
          v-if="isRendered.routesTo.calendar"
          :to="linkCalendar.link"
        >
          <img :src="icons.calendar" :alt="$t('layout.common.calendar_icon')" />

          <i v-if="isRendered.notifications.calendar" class="notif">
            {{ linkCalendar.notifications }}
          </i>
        </router-link>

        <router-link
          v-if="isRendered.routesTo.messages"
          :to="linkMessages.link"
          class="ml-20"
        >
          <img :src="icons.message" :alt="$t('layout.common.message_icon')" />

          <i v-if="isRendered.notifications.messages" class="notif">
            {{ linkMessages.notifications }}
          </i>
        </router-link>

        <router-link
          v-if="isRendered.routesTo.invoices"
          :to="routeToInvoices"
          class="ml-20"
        >
          <img :src="icons.payments" :alt="$t('layout.common.bill_icon')" />
        </router-link>

        <router-link :to="routeToProfile" class="wide">
          <img :src="icons.settings" :alt="$t('layout.common.settings_icon')" />
        </router-link>

        <router-link :to="routeToLogout" class="wide">
          <img :src="icons.exit" :alt="$t('layout.common.exit_icon')" />
        </router-link>
      </nav>

      <button
        class="s-mobile-menu__toggle"
        :class="{ opened }"
        @click="toggleMobileMenu"
      >
        <span v-for="i in 3" :key="i"></span>
      </button>

      <transition name="fade">
        <div v-if="opened" class="s-mobile-menu" :style="mobileMenuTopOffset">
          <div v-if="isRendered.btnSwitchToStudent" class="block">
            <div class="link link-dark" @click="onClickBtnSwitchToStudent">
              Перейти в кабинет ученика
            </div>
          </div>

          <div class="block">
            <TheHeaderMenuLink
              v-for="link in links"
              :key="link.labelPath"
              :link="link"
            />
          </div>

          <div class="block">
            <LanguageSelect
              v-if="isRendered.langSelect"
              v-model="lang"
              :options="langOptionsAvailable"
              icon-dark
              class="link"
            />

            <router-link :to="routeToProfile" class="link link-dark">
              {{ $t('layout.common.settings') }}
            </router-link>

            <router-link :to="routeToLogout" class="link link-dark">
              {{ $t('layout.common.log_off') }}
            </router-link>
          </div>
        </div>
      </transition>
    </div>
  </header>
</template>

<script>
import TheHeaderCredit from '@frontend/components/common/TheHeaderCredit.vue'
import TheHeaderRating from '@frontend/components/common/TheHeaderRating.vue'
import TheHeaderMenuLink from '@frontend/layouts/common/TheHeaderMenuLink.vue'
import ElAvatar from '@frontend/ui/ElAvatar.vue'
import ElButton from '@frontend/ui/ElButton.vue'
import { authUrl } from 'Helpers/const/authUrl'
import HeaderMixin from 'Helpers/mixins/layout/HeaderMixin'
import { showErrors } from 'Helpers/toast'
import iconBell from 'Static/images/@frontend/common-lk/icons/bell.svg'
import iconCalendar from 'Static/images/@frontend/common-lk/icons/calendar.svg'
import iconExit from 'Static/images/@frontend/common-lk/icons/exit.svg'
import iconMessage from 'Static/images/@frontend/common-lk/icons/message.svg'
import iconPayments from 'Static/images/@frontend/common-lk/icons/payments.svg'
import iconSettings from 'Static/images/@frontend/common-lk/icons/settings.svg'
import logoDefault from 'Static/images/@frontend/common-lk/logo.svg'
import { isEmpty, isNull } from 'lodash'
import { mapActions, mapGetters, mapState } from 'vuex'
import LanguageSelect from '@frontend/components/common/LanguageSelect.vue'
import { LangSwitchModuleClientMixin } from '@frontend/mixins/LangSwitchModuleClientMixin'

export default {
  name: 'TheHeaderClient',

  components: {
    LanguageSelect,
    ElButton,
    ElAvatar,
    TheHeaderMenuLink,
    TheHeaderRating,
    TheHeaderCredit,
  },

  mixins: [
    HeaderMixin,
    LangSwitchModuleClientMixin,
  ],

  props: {
    links: {
      type: Array,
      required: true,
    },

    fullName: {
      type: String,
      default: '',
    },

    abbreviation: {
      type: String,
      default: '',
    },

    avatar: {
      type: String,
      default: '',
    },

    noticeText: {
      type: String,
      default: '',
    },

    students: {
      type: Array,
      default: () => [],
    },

    schoolName: {
      type: String,
      default: '',
    },

    currentStudent: {
      type: Object,
      default: null,
    },

    hasEnrolledStudents: {
      type: Boolean,
      required: true,
    },

    hasContractsOrDrafts: {
      type: Boolean,
      required: true,
    },

    noticeAdditionalMessage: {
      type: String,
      default: '',
    },

    rating: {
      type: Object,
      default: null,
    },

    credit: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      isLoading: {
        btnSwitchToStudent: false,
      },

      logos: {
        default: logoDefault,
      },

      icons: {
        calendar: iconCalendar,
        settings: iconSettings,
        message: iconMessage,
        payments: iconPayments,
        bell: iconBell,
        exit: iconExit,
      },
    }
  },

  computed: {
    ...mapState('client/profile', [
      'profile',
    ]),

    ...mapState('client/students', [
      'studentToken',
    ]),

    ...mapGetters('client/invoices', [
      'hasInvoices',
    ]),

    ...mapGetters('client/students', [
      'isStudentEnrolledAndHasDiagnostics',
    ]),

    hasNoticeText() {
      return !!this.noticeText
    },

    hasStudents() {
      return this.students.length > 0
    },

    linkCalendar() {
      return this.getLinkByName('client-calendar')
    },

    linkNotifications() {
      return this.getLinkByName('client-notifications')
    },

    linkMessages() {
      return this.getLinkByName('client-messages')
    },

    studentsFormatted() {
      return this.students.map(this.getStudentFormatted)
    },

    currentStudentFormatted() {
      return !isEmpty(this.currentStudent)
        ? this.getStudentFormatted(this.currentStudent)
        : {}
    },

    hrefStudent() {
      const url = new URL(authUrl.userSwitch)

      if (this.studentToken) {
        url.searchParams.append('token', this.studentToken)
      }

      return url.href
    },

    routeToProfile() {
      return {
        name: 'client-profile',
      }
    },

    routeToLogout() {
      return {
        name: 'logout',
      }
    },

    routeToInvoices() {
      return {
        name: 'client-payment-invoices',
      }
    },

    routeToMain() {
      if (!this.hasEnrolledStudents && !this.hasContractsOrDrafts) {
        return {
          name: 'client-request-new',
        }
      }

      if (!this.hasEnrolledStudents && this.hasContractsOrDrafts) {
        return {
          name: 'client-contracts',
        }
      }

      if (this.isStudentEnrolledAndHasDiagnostics) {
        return {
          name: 'client-contracts',
        }
      }

      return '/'
    },

    cssClass() {
      return {
        header: {
          's-header-client': this.hasStudents,
        },

        headerWrapper: {
          noticed: this.hasNoticeText,
        },
      }
    },

    isRendered() {
      return {
        routesTo: {
          invoices: this.hasInvoices,
          notifications: !!this.linkNotifications,
          calendar: !!this.linkCalendar,
          messages: !!this.linkMessages,
        },

        notifications: {
          notifications: this.linkNotifications?.notifications > 0,
          calendar: this.linkCalendar?.notifications > 0,
          messages: this.linkMessages?.notifications > 0,
        },

        studentSelect: this.hasStudents,
        langSelect: this.isAvailableLangSwitchForCurrentStudent && !!this.lang,
        btnSwitchToStudent:
          this.hasStudents && this.currentStudent.canSwitchUser,

        rating: this.rating !== null,
        credit: this.credit !== null,
      }
    },
  },

  watch: {
    noticeText() {
      this.setHeightNoticedMobileMenu()
    },
  },

  methods: {
    ...mapActions('client/profile', [
      'updateLang',
    ]),

    ...mapActions('client/students', [
      'fetchStudentToken',
    ]),

    getLinkByName(name) {
      return this.links.find(({ link }) => link.name === name) ?? null
    },

    getAbbr(lastName, firstName) {
      return (
        (lastName ? lastName.slice(0, 1).toUpperCase() : '') +
        (firstName ? firstName.slice(0, 1).toUpperCase() : '')
      )
    },

    getName(lastName, firstName) {
      return (
        (!isEmpty(firstName) ? firstName : '') +
        (lastName ? ` ${lastName[0].toUpperCase()}.` : '')
      )
    },

    getGradeName(gradeName) {
      return gradeName
        ? `${gradeName} ${this.$t('layout.common.grade_ls')}`
        : ''
    },

    getStudentAvatar(photo) {
      return photo?.thumbs?.small ?? ''
    },

    getStudentFormatted(student) {
      const { lastName, firstName, grade, letter } = student
      const abbr = this.getAbbr(lastName, firstName)
      const name = this.getName(lastName, firstName)
      const gradeName = !isNull(letter)
        ? this.getGradeName(`${grade.name}-${letter}`)
        : this.getGradeName(grade.name)

      return {
        ...student,
        abbr,
        name,
        gradeName,
      }
    },

    selectStudent(student) {
      this.$emit('select-student', student.id)
    },

    async switchToStudent() {
      try {
        await this.fetchStudentToken()

        window.location.href = this.hrefStudent
      } catch (error) {
        showErrors(error)
      }
    },

    async onClickBtnSwitchToStudent() {
      this.isLoading.btnSwitchToStudent = true
      await this.switchToStudent()
      this.isLoading.btnSwitchToStudent = false
    },
  },
}
</script>
