var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "header",
    {
      ref: "header",
      staticClass: "s-header-wrapper",
      class: _vm.cssClass.headerWrapper,
    },
    [
      _vm.isRendered.noticeText
        ? _c("div", { staticClass: "s-header__notice" }, [
            _c(
              "span",
              [
                _vm.noticeText
                  ? [_vm._v("\n        " + _vm._s(_vm.noticeText) + "\n      ")]
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "span",
                  { staticClass: "notice-text--thin" },
                  [
                    _vm.isRendered.routeToPayment
                      ? _c(
                          "router-link",
                          {
                            staticClass: "text-underline text-dark",
                            attrs: {
                              to: _vm.routeToPayment,
                              rel: "noopener noreferrer",
                            },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.noticeAdditionalMessage) +
                                "\n        "
                            ),
                          ]
                        )
                      : [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.noticeAdditionalMessage) +
                              "\n        "
                          ),
                        ],
                  ],
                  2
                ),
              ],
              2
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "s-header" },
        [
          _c(
            "router-link",
            {
              staticClass: "s-logo s-header__logo",
              attrs: { to: _vm.routeToMain },
            },
            [
              !_vm.isSchoolEducont
                ? _c("h2", { staticClass: "s-school-name" }, [
                    _vm._v(_vm._s(_vm.schoolName)),
                  ])
                : _c("div", { staticClass: "s-school-name" }, [
                    _c("img", {
                      staticClass: "s-logo-educont",
                      attrs: { src: _vm.logos.educont, alt: "logo educont" },
                    }),
                  ]),
              _vm._v(" "),
              _c("img", {
                attrs: { src: _vm.logos.default, alt: _vm.t("logotype") },
              }),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "s-header__profile" },
            [
              _c("ElAvatar", {
                class: _vm.cssClass.abbr,
                attrs: { abbreviation: _vm.abbreviation, image: _vm.avatar },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "text" }, [
                _c("span", { staticClass: "name" }, [
                  _vm._v(_vm._s(_vm.fullName)),
                ]),
                _vm._v(" "),
                _vm.isRendered.gradeName
                  ? _c("span", { staticClass: "grade" }, [
                      _vm._v(
                        "\n          " + _vm._s(_vm.gradeName) + "\n        "
                      ),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _vm.isRendered.gradeSelect
                ? _c("v-select", {
                    staticClass: "v-select-student v-select-grade ml-3",
                    attrs: {
                      label: "abbr",
                      placeholder: "Выберите класс",
                      clearable: false,
                      searchable: false,
                      options: _vm.gradeOptions,
                      value: _vm.selectedGrade,
                    },
                    on: { input: _vm.onInputGrade },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "selected-option",
                          fn: function (option) {
                            return [
                              _c("div", { staticClass: "s-header__student" }, [
                                _c("div", { staticClass: "text" }, [
                                  _c("span", { staticClass: "name" }, [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.t("select_class")) +
                                        "\n              "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "grade" }, [
                                    _vm._v(_vm._s(option.gradeName)),
                                  ]),
                                ]),
                              ]),
                            ]
                          },
                        },
                        {
                          key: "option",
                          fn: function (option) {
                            return [
                              _c(
                                "div",
                                { staticClass: "s-header__student-option" },
                                [
                                  _c("b", { staticClass: "name" }, [
                                    _vm._v(_vm._s(option.gradeName)),
                                  ]),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      841408883
                    ),
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _vm.isRendered.switchToClient
            ? [
                _c("ElButton", {
                  staticClass: "btn btn-outline-light ml-4 d-none d-lg-block",
                  attrs: { label: _vm.t("go_to_client_account") },
                  on: { click: _vm.onClickBtnSwitchToClient },
                }),
                _vm._v(" "),
                _c("ModalClientPassword", { ref: "modalClientPassword" }),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.isRendered.rating
            ? _c(
                "div",
                { staticClass: "s-header__rating-desktop-display" },
                [_c("TheHeaderRating", { attrs: { rating: _vm.rating } })],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isRendered.credit
            ? _c(
                "div",
                { staticClass: "s-header__rating-desktop-display" },
                [_c("TheHeaderCredit", { attrs: { credit: _vm.credit } })],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "nav",
            { staticClass: "s-header__nav" },
            [
              _vm.isRendered.langSelect
                ? _c("LanguageSelect", {
                    staticClass: "v-select-language mr-2",
                    attrs: { options: _vm.langOptionsAvailable },
                    model: {
                      value: _vm.lang,
                      callback: function ($$v) {
                        _vm.lang = $$v
                      },
                      expression: "lang",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.isRendered.routeTo.notifications
                ? _c(
                    "router-link",
                    { attrs: { to: _vm.linkNotifications.link } },
                    [
                      _c("img", {
                        attrs: {
                          src: _vm.icons.bell,
                          alt: _vm.t("notification_icon"),
                        },
                      }),
                      _vm._v(" "),
                      _vm.isRendered.notifications.notifications
                        ? _c("i", { staticClass: "notif" }, [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.linkNotifications.notifications) +
                                "\n        "
                            ),
                          ])
                        : _vm._e(),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isRendered.routeTo.calendar
                ? _c("router-link", { attrs: { to: _vm.linkCalendar.link } }, [
                    _c("img", {
                      attrs: {
                        src: _vm.icons.calendar,
                        alt: _vm.t("calendar_icon"),
                      },
                    }),
                    _vm._v(" "),
                    _vm.isRendered.notifications.calendar
                      ? _c("i", { staticClass: "notif" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.linkCalendar.notifications) +
                              "\n        "
                          ),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.isRendered.routeTo.messages
                ? _c(
                    "router-link",
                    {
                      staticClass: "ml-20",
                      attrs: { to: _vm.linkMessages.link },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: _vm.icons.message,
                          alt: _vm.t("message_icon"),
                        },
                      }),
                      _vm._v(" "),
                      _vm.isRendered.notifications.messages
                        ? _c("i", { staticClass: "notif" }, [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.linkMessages.notifications) +
                                "\n        "
                            ),
                          ])
                        : _vm._e(),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isRendered.routeTo.profile
                ? _c("router-link", { attrs: { to: _vm.routeToProfile } }, [
                    _c("img", {
                      attrs: {
                        src: _vm.icons.settings,
                        alt: _vm.t("settings_icon"),
                      },
                    }),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "router-link",
                { staticClass: "wide", attrs: { to: _vm.routeToLogout } },
                [
                  _c("img", {
                    attrs: { src: _vm.icons.exit, alt: _vm.t("exit_icon") },
                  }),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "s-mobile-menu__toggle",
              class: { opened: _vm.opened },
              on: { click: _vm.toggleMobileMenu },
            },
            _vm._l(3, function (i) {
              return _c("span", { key: i })
            }),
            0
          ),
          _vm._v(" "),
          _c("transition", { attrs: { name: "fade" } }, [
            _vm.opened
              ? _c(
                  "div",
                  {
                    staticClass: "s-mobile-menu",
                    style: _vm.mobileMenuTopOffset,
                  },
                  [
                    _vm.isRendered.switchToClient
                      ? _c("div", { staticClass: "block" }, [
                          _c(
                            "div",
                            {
                              staticClass: "link link-dark",
                              on: { click: _vm.onClickBtnSwitchToClient },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.t("go_to_client_account")) +
                                  "\n          "
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "block" },
                      _vm._l(_vm.links, function (link) {
                        return _c("TheHeaderMenuLink", {
                          key: link.link.name,
                          attrs: { link: link },
                        })
                      }),
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "block" },
                      [
                        _vm.isRendered.langSelect
                          ? _c("LanguageSelect", {
                              staticClass: "link",
                              attrs: {
                                options: _vm.langOptionsAvailable,
                                "icon-dark": "",
                              },
                              model: {
                                value: _vm.lang,
                                callback: function ($$v) {
                                  _vm.lang = $$v
                                },
                                expression: "lang",
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.isRendered.routeTo.profile
                          ? _c(
                              "router-link",
                              {
                                staticClass: "link link-dark",
                                attrs: { to: _vm.routeToProfile },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.t("settings")) +
                                    "\n          "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "router-link",
                          {
                            staticClass: "link link-dark",
                            attrs: { to: _vm.routeToLogout },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.t("log_off")) +
                                "\n          "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                )
              : _vm._e(),
          ]),
        ],
        2
      ),
      _vm._v(" "),
      _vm.rating
        ? _c(
            "div",
            { staticClass: "s-header__rating-mobile-tablet-display" },
            [_c("TheHeaderRating", { attrs: { rating: _vm.rating } })],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }