var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container h-100" }, [
    _c("div", { staticClass: "row h-100" }, [
      _c(
        "div",
        {
          staticClass:
            "col-12 d-flex flex-column align-items-center justify-content-center text-center",
        },
        [
          _c("h1", { staticClass: "mb-4" }, [_vm._v(_vm._s(_vm.pageTitle))]),
          _vm._v(" "),
          _c(
            "router-link",
            { staticClass: "btn btn-primary", attrs: { to: "/" } },
            [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("pages.go_back_to_the_main_page")) +
                  "\n      "
              ),
            ]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }