var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "header",
    {
      ref: "header",
      staticClass: "s-header-wrapper",
      class: _vm.cssClass.headerWrapper,
    },
    [
      _vm.noticeText
        ? _c("div", { staticClass: "s-header__notice" }, [
            _c("span", [_vm._v(_vm._s(_vm.noticeText))]),
            _vm._v(" "),
            _c("span", { staticClass: "notice-text--thin" }, [
              _vm._v(_vm._s(_vm.noticeAdditionalMessage)),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "s-header", class: _vm.cssClass.header },
        [
          _c(
            "router-link",
            {
              staticClass: "s-logo s-header__logo",
              attrs: { to: _vm.routeToMain },
            },
            [
              _c("h2", { staticClass: "s-school-name" }, [
                _vm._v(_vm._s(_vm.schoolName)),
              ]),
              _vm._v(" "),
              _c("hr", { staticClass: "s-school-divider" }),
              _vm._v(" "),
              _c("img", {
                staticClass: "s-school-logo",
                attrs: {
                  src: _vm.logos.default,
                  alt: _vm.$t("layout.common.logotype"),
                },
              }),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "s-header__profile d-lg-none d-xl-flex" },
            [
              _c("ElAvatar", {
                attrs: {
                  abbreviation: _vm.abbreviation,
                  image: _vm.avatar,
                  shape: "circle",
                },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "text" }, [
                _c("span", { staticClass: "name" }, [
                  _vm._v(_vm._s(_vm.fullName)),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _vm.isRendered.studentSelect
            ? _c(
                "div",
                { staticClass: "s-header__student-select" },
                [
                  _c("v-select", {
                    staticClass: "v-select-student",
                    attrs: {
                      value: _vm.currentStudentFormatted,
                      options: _vm.studentsFormatted,
                      clearable: false,
                      searchable: false,
                      label: "abbr",
                    },
                    on: { input: _vm.selectStudent },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "selected-option",
                          fn: function ({ abbr, name, gradeName, photo }) {
                            return [
                              _c(
                                "div",
                                { staticClass: "s-header__student" },
                                [
                                  _c("ElAvatar", {
                                    attrs: {
                                      abbreviation: abbr,
                                      image: _vm.getStudentAvatar(photo),
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "text" }, [
                                    _c("span", { staticClass: "name" }, [
                                      _vm._v(_vm._s(name)),
                                    ]),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "grade" }, [
                                      _vm._v(_vm._s(gradeName)),
                                    ]),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                        {
                          key: "option",
                          fn: function ({ name, gradeName }) {
                            return [
                              _c(
                                "div",
                                { staticClass: "s-header__student-option" },
                                [
                                  _c("b", { staticClass: "name" }, [
                                    _vm._v(_vm._s(name)),
                                  ]),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "t-tag" }, [
                                    _vm._v(_vm._s(gradeName)),
                                  ]),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3868103365
                    ),
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isRendered.btnSwitchToStudent
            ? _c("ElButton", {
                staticClass: "btn-outline-light d-none d-lg-block",
                attrs: {
                  "is-loading": _vm.isLoading.btnSwitchToStudent,
                  label: "Перейти в кабинет ученика",
                },
                on: { click: _vm.onClickBtnSwitchToStudent },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.isRendered.rating
            ? _c("TheHeaderRating", { attrs: { rating: _vm.rating } })
            : _vm._e(),
          _vm._v(" "),
          _vm.isRendered.credit
            ? _c("TheHeaderCredit", { attrs: { rating: _vm.credit } })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "nav",
            { staticClass: "s-header__nav" },
            [
              _vm.isRendered.langSelect
                ? _c("LanguageSelect", {
                    staticClass: "v-select-language mr-2",
                    attrs: { options: _vm.langOptionsAvailable },
                    model: {
                      value: _vm.lang,
                      callback: function ($$v) {
                        _vm.lang = $$v
                      },
                      expression: "lang",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.isRendered.routesTo.notifications
                ? _c(
                    "router-link",
                    { attrs: { to: _vm.linkNotifications.link } },
                    [
                      _c("img", {
                        attrs: {
                          src: _vm.icons.bell,
                          alt: _vm.$t("layout.common.notification_icon"),
                        },
                      }),
                      _vm._v(" "),
                      _vm.isRendered.notifications.notifications
                        ? _c("i", { staticClass: "notif" }, [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.linkNotifications.notifications) +
                                "\n        "
                            ),
                          ])
                        : _vm._e(),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isRendered.routesTo.calendar
                ? _c("router-link", { attrs: { to: _vm.linkCalendar.link } }, [
                    _c("img", {
                      attrs: {
                        src: _vm.icons.calendar,
                        alt: _vm.$t("layout.common.calendar_icon"),
                      },
                    }),
                    _vm._v(" "),
                    _vm.isRendered.notifications.calendar
                      ? _c("i", { staticClass: "notif" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.linkCalendar.notifications) +
                              "\n        "
                          ),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.isRendered.routesTo.messages
                ? _c(
                    "router-link",
                    {
                      staticClass: "ml-20",
                      attrs: { to: _vm.linkMessages.link },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: _vm.icons.message,
                          alt: _vm.$t("layout.common.message_icon"),
                        },
                      }),
                      _vm._v(" "),
                      _vm.isRendered.notifications.messages
                        ? _c("i", { staticClass: "notif" }, [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.linkMessages.notifications) +
                                "\n        "
                            ),
                          ])
                        : _vm._e(),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isRendered.routesTo.invoices
                ? _c(
                    "router-link",
                    {
                      staticClass: "ml-20",
                      attrs: { to: _vm.routeToInvoices },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: _vm.icons.payments,
                          alt: _vm.$t("layout.common.bill_icon"),
                        },
                      }),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "router-link",
                { staticClass: "wide", attrs: { to: _vm.routeToProfile } },
                [
                  _c("img", {
                    attrs: {
                      src: _vm.icons.settings,
                      alt: _vm.$t("layout.common.settings_icon"),
                    },
                  }),
                ]
              ),
              _vm._v(" "),
              _c(
                "router-link",
                { staticClass: "wide", attrs: { to: _vm.routeToLogout } },
                [
                  _c("img", {
                    attrs: {
                      src: _vm.icons.exit,
                      alt: _vm.$t("layout.common.exit_icon"),
                    },
                  }),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "s-mobile-menu__toggle",
              class: { opened: _vm.opened },
              on: { click: _vm.toggleMobileMenu },
            },
            _vm._l(3, function (i) {
              return _c("span", { key: i })
            }),
            0
          ),
          _vm._v(" "),
          _c("transition", { attrs: { name: "fade" } }, [
            _vm.opened
              ? _c(
                  "div",
                  {
                    staticClass: "s-mobile-menu",
                    style: _vm.mobileMenuTopOffset,
                  },
                  [
                    _vm.isRendered.btnSwitchToStudent
                      ? _c("div", { staticClass: "block" }, [
                          _c(
                            "div",
                            {
                              staticClass: "link link-dark",
                              on: { click: _vm.onClickBtnSwitchToStudent },
                            },
                            [
                              _vm._v(
                                "\n            Перейти в кабинет ученика\n          "
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "block" },
                      _vm._l(_vm.links, function (link) {
                        return _c("TheHeaderMenuLink", {
                          key: link.labelPath,
                          attrs: { link: link },
                        })
                      }),
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "block" },
                      [
                        _vm.isRendered.langSelect
                          ? _c("LanguageSelect", {
                              staticClass: "link",
                              attrs: {
                                options: _vm.langOptionsAvailable,
                                "icon-dark": "",
                              },
                              model: {
                                value: _vm.lang,
                                callback: function ($$v) {
                                  _vm.lang = $$v
                                },
                                expression: "lang",
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "router-link",
                          {
                            staticClass: "link link-dark",
                            attrs: { to: _vm.routeToProfile },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("layout.common.settings")) +
                                "\n          "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "router-link",
                          {
                            staticClass: "link link-dark",
                            attrs: { to: _vm.routeToLogout },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("layout.common.log_off")) +
                                "\n          "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                )
              : _vm._e(),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }