var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "card cursor-pointer",
      class: _vm.$style.productCard,
      on: { click: _vm.onClickLogClicksPromoMaterials },
    },
    [
      _c(
        "div",
        {
          staticClass:
            "card-body overflow-hidden p-3 pb-sm-4 d-flex align-items-start flex-sm-column",
        },
        [
          _c(
            "div",
            { staticClass: "d-block d-sm-none", class: _vm.$style.image },
            [
              _c("ElImage", {
                attrs: {
                  "image-id": _vm.image.id,
                  src: _vm.image.src,
                  alt: _vm.image.alt,
                  breakpoints: _vm.image.breakpoints,
                  loading: "lazy",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "d-flex flex-column ml-3 ml-sm-0 h-100" }, [
            _c("div", { staticClass: "px-0" }, [
              _c("span", { staticClass: "btn-tag btn-tag-blue" }, [
                _vm._v(
                  "\n          " + _vm._s(_vm.product.type.name) + "\n        "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "d-flex flex-wrap-reverse flex-sm-wrap" },
              [
                _c(
                  "div",
                  { staticClass: "h4 mt-2 mt-sm-3 mb-0 text-body w-100" },
                  [
                    _vm._v(
                      "\n          " + _vm._s(_vm.product.name) + "\n        "
                    ),
                  ]
                ),
                _vm._v(" "),
                _vm.dateInfoText
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "mt-2 text-secondary font-weight-medium w-100",
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.dateInfoText) +
                            "\n        "
                        ),
                      ]
                    )
                  : _vm._e(),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "d-flex flex-column justify-content-end flex-grow-1",
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "d-none d-sm-block mt-3",
                    class: _vm.$style.image,
                  },
                  [
                    _c("ElImage", {
                      class: _vm.$style.imageContainer,
                      attrs: {
                        "image-id": _vm.image.id,
                        src: _vm.image.src,
                        alt: _vm.image.alt,
                        loading: "lazy",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "mt-2 mt-sm-3 font-weight-normal text-body",
                    class: _vm.$style.minPrice,
                  },
                  [
                    _vm._v(
                      "\n          " + _vm._s(_vm.minPriceText) + "\n        "
                    ),
                  ]
                ),
              ]
            ),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }