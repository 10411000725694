import ApiBase from 'ApiRest/ApiBase'
import UrlHelper from 'Helpers/UrlHelper'

export const ApiClientInvoices = new (class extends ApiBase {
  /**
   * Список счетов клиента
   *
   * @param {Object} parameters
   * @returns {Promise}
   */
  get(parameters = {}) {
    const urlParams = UrlHelper.param(parameters)
    const url = `/account/invoices?${urlParams}`

    return this._GET(url)
  }
})()
