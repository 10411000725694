import ApiBase from 'ApiRest/ApiBase'

export const ApiStudentFeOffer = new (class extends ApiBase {
  /**
   * Договор оферты в формате docx
   *
   * @param {number} schoolId - id школы
   * @returns {Promise}
   */
  get(schoolId) {
    const url = `/account/student/family-education-offer-for-school/${schoolId}`

    return this._GET(url, {
      responseType: 'arraybuffer',
    })
  }
})()
