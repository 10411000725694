var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "nav", class: _vm.$style.tabs },
    _vm._l(_vm.tabs, function (tab) {
      return _c(
        "div",
        {
          key: tab.id,
          ref: tab.id,
          refInFor: true,
          staticClass: "cursor-pointer link-blue",
          class: [
            _vm.$style.tabItem,
            { [_vm.$style.tabItemActive]: _vm.currentTab === tab.id },
          ],
          on: {
            click: function ($event) {
              return _vm.onClickTabNav(tab.id)
            },
          },
        },
        [_vm._v("\n    " + _vm._s(tab.label) + "\n  ")]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }