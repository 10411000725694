import { helpers } from 'vuelidate/lib/validators'

export const validateStrongPassword = function (value) {
  const passwordLength = 8
  const allowedSymbols = /[a-z0-9#$%&'"*+/\\=!?^_`[\]{|}~-]+$/g
  const numberSymbol = /[\d]/g
  const upperCaseSymbol = /[A-Z]/g
  const lowerCaseSymbol = /[a-z]/g

  const isSymbolsCorrect = allowedSymbols.test(String(value).toLowerCase())
  const hasNumber = numberSymbol.test(String(value))
  const hasUpperCase = upperCaseSymbol.test(String(value))
  const hasLowerCase = lowerCaseSymbol.test(String(value))
  const isLengthCorrect = value.replace(/ /g, '').length >= passwordLength

  return (
    isSymbolsCorrect &&
    hasNumber &&
    hasUpperCase &&
    hasLowerCase &&
    isLengthCorrect
  )
}

// TODO: i18n
export const strongPasswordErrorMessage =
  'Пароль должен состоять минимум из 8 символов' +
  ', содержать только латиницу, спецсимволы' +
  ', хотя бы одна большая и маленькая буква' +
  ', и цифра. Не должно совпадать с e-mail'

// TODO: i18n
export const requiredErrorMessage = 'Поле обязательно для заполнения'

// TODO: i18n
export const firstNameErrorMessage =
  'Имя обязательно для заполнения и может содержать только буквы'

// TODO: i18n
export const emailErrorMessage =
  'Введите валидный email. Пример: example@mail.ru'

// TODO: i18n
export const urlErrorMessage =
  'Введите валидный url. Пример: https://example.ru'

// TODO: i18n
export const checkForErrorsMessage = 'Проверьте данные на наличие ошибок'

export const validateSubdomain = function (value) {
  const re = /[a-z0-9-]+$/

  return re.test(String(value).toLowerCase())
}

export const validateEmail = function (value) {
  const re =
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/

  return re.test(String(value).toLowerCase())
}

export const validateDate = function (value) {
  const re =
    /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/

  return re.test(String(value).toLowerCase())
}

export const validatePhone = function (value) {
  const amountOfNumbers = 10
  const re = /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){10,}(\s*)?$/

  return (
    re.test(String(value).toLowerCase()) &&
    value.replace(/\D+/g, '').length >= amountOfNumbers
  )
}

export const validateName = function (value) {
  const re = /[a-zа-яё -]+$/

  return re.test(String(value).toLowerCase()) && value.length >= 1
}

export const check = ({ state, commit }, name, value) => {
  commit('INPUT_SET_VALID', {
    value,
    name,
  })
  commit('INPUT_SET_CHECK', {
    value: true,
    name,
  })

  return state[name].valid
}

export const validateIf = (prop, validator) =>
  helpers.withParams(
    {
      type: 'validatedIf',
      prop,
    },
    // eslint-disable-next-line func-names
    function (value, parentVm) {
      return helpers.ref(prop, this, parentVm) ? validator(value) : true
    },
  )

export const exactLength = (param) =>
  helpers.withParams(
    {
      type: 'exactLength',
      value: param,
    },
    (value) => value?.length === param,
  )
