<template>
  <footer class="s-footer">
    <div :class="$style.footerContainer">
      <div>
        <ul v-if="isPreview" class="list-unstyled s-footer__nav">
          <li>
            <span class="link-dark">
              {{ t('school_website') }}
            </span>
          </li>

          <li>
            <span class="link-dark">
              {{ t('privacy_policy') }}
            </span>
          </li>

          <li>
            <span class="link-dark">
              {{ t('information_security_memo') }}
            </span>
          </li>
        </ul>

        <ul v-else class="list-unstyled s-footer__nav">
          <li v-if="schoolSite">
            <a
              class="link-dark"
              :href="schoolSite"
              target="_blank"
              rel="noopener noreferrer"
            >
              {{ t('school_website') }}
            </a>
          </li>

          <!--
            <li> <a class="link-dark" href="">Популярные вопросы</a></li>
            <li> <a class="link-dark" href="">Техническая поддержка</a></li>
          -->

          <li v-if="schoolPolicyFileUrl">
            <a
              class="link-dark"
              :href="schoolPolicyFileUrl"
              target="_blank"
              rel="noopener noreferrer"
            >
              {{ t('privacy_policy') }}
            </a>
          </li>

          <li>
            <a
              class="link-dark"
              :href="docs.informationSecurityMemo"
              target="_blank"
              rel="noopener noreferrer"
            >
              {{ t('information_security_memo') }}
            </a>
          </li>
        </ul>

        <div class="text-secondary">
          <div class="mb-2">© {{ t('intelligent_technologies') }}</div>

          <div>{{ t('id') }} {{ userId }}</div>
        </div>
      </div>

      <div :class="$style.iconsBlock">
        <a
          class="mx-3"
          href="https://vk.com/ibls.school"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img :src="icons.iconVK" alt="vk-icon" />
        </a>

        <a
          class="mx-3"
          href="https://ok.ru/group/58795614011399"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img :src="icons.iconOdnoklassniki" alt="odnoklassniki-icon" />
        </a>

        <a
          class="mx-3"
          href="https://t.me/iblschool"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img :src="icons.iconTelegram" alt="telegram-icon" />
        </a>

        <a
          class="mx-3"
          href="https://www.youtube.com/ibls-one"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img :src="icons.iconYoutube" alt="youtube-icon" />
        </a>
      </div>
    </div>
  </footer>
</template>

<script>
import informationSecurityMemo from 'Static/docs/footer/informationSecurityMemo.pdf'
import iconTelegram from 'Static/images/@frontend/layouts/footer/telegram.svg'
import iconYoutube from 'Static/images/@frontend/layouts/footer/youtube.svg'
import iconOdnoklassniki from 'Static/images/@frontend/layouts/footer/odnoklassniki.svg'
import iconVK from 'Static/images/@frontend/layouts/footer/vk.svg'

export default {
  name: 'TheFooter',

  props: {
    isPreview: {
      type: Boolean,
      default: false,
    },

    school: {
      type: Object,
      default: null,
    },

    userId: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      docs: {
        informationSecurityMemo,
      },

      icons: {
        iconOdnoklassniki,
        iconTelegram,
        iconYoutube,
        iconVK,
      },
    }
  },

  computed: {
    schoolSite() {
      return this.school?.site
    },

    schoolPolicyFileUrl() {
      return this.school?.policyFile?.fileUrl
    },
  },

  methods: {
    /**
     * @param {string} name
     * @returns {string}
     */
    t(name) {
      return this.$t(`layout.common.${name}`)
    },
  },
}
</script>

<style lang="scss" module>
@import '~@frontend/scss/variables';

.footerContainer {
  display: flex;
  justify-content: space-between;

  @media (max-width: $md) {
    flex-direction: column-reverse;
  }
}

.iconsBlock {
  @media (max-width: $md) {
    margin: 0 auto 24px;
  }
}
</style>
