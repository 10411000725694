var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.isRendered.externalUrl
        ? _c(
            "a",
            {
              staticClass: "link link-dark d-flex align-items-center",
              class: _vm.link.class,
              attrs: {
                target: _vm.link.isExternal ? "_blank" : false,
                href: _vm.link.externalUrl,
                rel: _vm.link.isExternal ? "noopener noreferrer" : false,
              },
            },
            [
              _c("span", [_vm._v(_vm._s(_vm.$t(_vm.link.labelPath)))]),
              _vm._v(" "),
              _vm.isRendered.notification
                ? _c("i", { staticClass: "notif ml-2" }, [
                    _vm._v(
                      "\n      " + _vm._s(_vm.link.notifications) + "\n    "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.isRendered.iconLinkExternal
                ? _c("img", {
                    staticClass: "ml-2",
                    attrs: {
                      src: _vm.icons.linkExternal,
                      alt: "external link",
                    },
                  })
                : _vm._e(),
            ]
          )
        : [
            _vm.isRendered.children
              ? [
                  _c(
                    "div",
                    {
                      staticClass:
                        "link link-dark d-flex align-items-center justify-content-between",
                      class: _vm.linkCssClass,
                      on: { click: _vm.onClickGroup },
                    },
                    [
                      _c("span", [_vm._v(_vm._s(_vm.$t(_vm.link.labelPath)))]),
                      _vm._v(" "),
                      _c("div", { staticClass: "mr-2" }, [
                        _vm.isVisibleChild
                          ? _c("i", { staticClass: "fas fa-angle-down fa-lg" })
                          : _c("i", {
                              staticClass: "fas fa-angle-right fa-lg",
                            }),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _vm._l(_vm.link.children, function (child) {
                    return _c("TheHeaderMenuLink", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isVisibleChild,
                          expression: "isVisibleChild",
                        },
                      ],
                      key: child.label,
                      class: _vm.$style.linkWithChildren,
                      attrs: { link: child },
                    })
                  }),
                ]
              : _c(
                  "router-link",
                  {
                    staticClass: "link link-dark d-flex align-items-center",
                    class: _vm.link.class,
                    attrs: { to: _vm.link.link },
                  },
                  [
                    _vm._v(
                      "\n      " +
                        _vm._s(_vm.$t(_vm.link.labelPath)) +
                        "\n\n      "
                    ),
                    _vm.isRendered.notification
                      ? _c("i", { staticClass: "notif ml-2" }, [
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.link.notifications) +
                              "\n      "
                          ),
                        ])
                      : _vm._e(),
                  ]
                ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }