<template>
  <div class="form-group" :class="{ 'is-invalid': invalid }">
    <label class="form-label">
      {{ label }}&nbsp;<span v-if="required" class="text-pink">*</span>
    </label>

    <v-date-picker
      v-bind="$attrs"
      :locale="localeLang"
      :mask="mask"
      :popover="{ visibility: 'hidden' }"
      v-on="$listeners"
    >
      <template #default="{ inputValue, inputEvents, togglePopover }">
        <input
          type="text"
          :value="inputValue"
          class="form-control form-control-date"
          :disabled="disabled"
          @focus="togglePopover"
          v-on="inputEvents"
        />
      </template>
    </v-date-picker>

    <div class="form-invalid">
      <slot name="invalid" />
    </div>
  </div>
</template>

<script>
import { getCookie } from 'Helpers/cookie'

// TODO: перенести сюда логику трансформации даты по примеру с Calendar.vue
export default {
  name: 'ElDatePicker',

  inheritAttrs: false,

  props: {
    label: {
      type: String,
      default: '',
    },

    required: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    invalid: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      mask: 'dd.mm.yyyy',
    }
  },

  computed: {
    localeLang() {
      return getCookie('lang') || 'ru'
    },
  },
}
</script>
