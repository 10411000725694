var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { class: _vm.cssClassCard, on: { click: _vm.onClickCard } },
    [
      _c("div", [
        _c("img", {
          class: _vm.$style.cardImage,
          attrs: { src: _vm.image.src, loading: "lazy", alt: "cover" },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "ml-3 py-2" }, [
        _c("h4", { staticClass: "h4 mb-2" }, [
          _vm._v(_vm._s(_vm.product.name)),
        ]),
        _vm._v(" "),
        _vm.dateInfoText
          ? _c("p", { staticClass: "text-gray font-size-12 mb-2" }, [
              _vm._v("\n      " + _vm._s(_vm.dateInfoText) + "\n    "),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("strong", { staticClass: "h3 mb-0", class: _vm.$style.cardPrice }, [
          _vm._v(_vm._s(_vm.priceText)),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { class: _vm.$style.cardCheck }, [
        _c("img", {
          attrs: { src: _vm.icons.check, alt: "icon check", width: "12" },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }