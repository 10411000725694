import { flatten } from 'flat'
import { isArray, isPlainObject } from 'lodash'

/**
 *
 * Создает html список из элементов аргумента
 *
 * @param {Array|Object} items
 * @throws {Error}
 * @returns {HTMLUListElement}
 */
export const getList = (items) => {
  if (!isArray(items) && !isPlainObject(items)) {
    throw new Error('Items must be type of Array or Object.')
  }

  const listItems = isPlainObject(items) ? Object.values(flatten(items)) : items

  const list = document.createElement('ul')

  listItems.forEach((err) => {
    const li = document.createElement('li')

    li.textContent = err
    list.appendChild(li)
  })

  return list
}
