var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hasLinks
    ? _c(
        "aside",
        { staticClass: "s-aside", class: { narrow: _vm.isHidden } },
        [
          _c(
            "ElButton",
            { staticClass: "s-aside__toggle", on: { click: _vm.toggle } },
            [
              _c("img", {
                attrs: { src: _vm.icons.arrowLeft, alt: "arrow-left" },
              }),
            ]
          ),
          _vm._v(" "),
          _c(
            "nav",
            { staticClass: "s-aside__menu" },
            _vm._l(_vm.links, function (link) {
              return _c("TheAsideLink", {
                key: link.link.name,
                attrs: { link: link },
              })
            }),
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }