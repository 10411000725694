var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ElModal",
    {
      staticClass: "modal-content",
      attrs: { name: _vm.name, width: 500, adaptive: true, transition: "none" },
    },
    [
      _c(
        "div",
        { staticClass: "vm--modal-content" },
        [
          _c(
            "ElButton",
            {
              staticClass: "btn-img vm--modal-close",
              on: { click: _vm.onClickBtnClose },
            },
            [
              _c("img", {
                attrs: { src: _vm.icons.iconClose, alt: "icon close" },
              }),
            ]
          ),
          _vm._v(" "),
          _c("h3", { staticClass: "h3" }, [
            _vm._v("Приветствуем вас на платформе IBLS!"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "mb-3 text-gray" }, [
            _vm._v(
              "\n      Сейчас вы находитесь в личном кабинете клиента. Для начала обучения\n      перейдите в личный кабинет ученика – это можно сделать, нажав на кнопку,\n      расположенную на верхней синей панели. В кабинете ученика вы найдете\n      видеоуроки, тесты и демо-версии контрольных работ.\n    "
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "mb-5 text-gray" }, [
            _vm._v(
              "\n      Из личного кабинета клиента можно отслеживать успеваемость обучающегося,\n      а также подать заявление на промежуточную аттестацию. Данные для входа в\n      личный кабинет ученика были отправлены на его электронную почту,\n      указанную вами при регистрации.\n    "
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            [
              _c("ElButton", {
                staticClass: "btn-primary",
                attrs: { label: "Приступить к обучению" },
                on: { click: _vm.onClickBtnStartSchool },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }