import ApiBase from 'ApiRest/ApiBase'

export default new (class List extends ApiBase {
  /**
   * Получить все Прохождения Теста у теста аттестации
   *
   * @param {number} id
   * @returns {Promise}
   */

  get(id) {
    return this._GET(`/account/student/test/cert-tests/${id}/testings`)
  }
})()
