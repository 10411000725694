var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "d-flex justify-content-between align-items-center",
      class: _vm.$style.controls,
    },
    [
      _c(
        "button",
        { staticClass: "btn-prev", on: { click: _vm.onClickPrev } },
        [
          _c("img", {
            staticClass: "icon",
            attrs: { src: _vm.icons.prev, alt: "icon prev" },
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "d-flex justify-content-lg-center px-lg-2" },
        _vm._l(_vm.dots, function (_, i) {
          return _c("span", {
            key: i,
            staticClass: "dot",
            class: _vm.cssClassBtnDot(i),
            on: {
              click: function ($event) {
                return _vm.onClickDot(i)
              },
            },
          })
        }),
        0
      ),
      _vm._v(" "),
      _c(
        "button",
        { staticClass: "btn-next", on: { click: _vm.onClickNext } },
        [
          _c("img", {
            staticClass: "icon",
            attrs: { src: _vm.icons.next, alt: "icon next" },
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }