var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "form-group", class: { "is-invalid": _vm.invalid } },
    [
      _c("label", { staticClass: "form-label" }, [
        _vm._v("\n    " + _vm._s(_vm.label) + " "),
        _vm.required
          ? _c("span", { staticClass: "text-pink" }, [_vm._v("*")])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c(
        "v-date-picker",
        _vm._g(
          _vm._b(
            {
              attrs: {
                locale: _vm.localeLang,
                mask: _vm.mask,
                popover: { visibility: "hidden" },
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ inputValue, inputEvents, togglePopover }) {
                    return [
                      _c(
                        "input",
                        _vm._g(
                          {
                            staticClass: "form-control form-control-date",
                            attrs: { type: "text", disabled: _vm.disabled },
                            domProps: { value: inputValue },
                            on: { focus: togglePopover },
                          },
                          inputEvents
                        )
                      ),
                    ]
                  },
                },
              ]),
            },
            "v-date-picker",
            _vm.$attrs,
            false
          ),
          _vm.$listeners
        )
      ),
      _vm._v(" "),
      _c("div", { staticClass: "form-invalid" }, [_vm._t("invalid")], 2),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }