import ApiBase from 'ApiRest/ApiBase'

export const ApiAccountStudentDiagnosticCtpTest = new (class extends ApiBase {
  /**
   * Данные ктп теста входящей диагностики
   *
   * @param {number} ctpId
   * @returns {Promise}
   */
  get(ctpId) {
    const url = `/account/student/diagnostics/ctp-tests/${ctpId}`

    return this._GET(url)
  }
})()
