<template>
  <div id="app">
    <transition name="fade-layout" mode="out-in">
      <router-view v-if="!isLoading" key="main" />
      <LoaderFullScreen v-else key="loader" />
    </transition>
  </div>
</template>

<script>
import LoaderFullScreen from 'CommonComponents/LoaderFullScreen.vue'
import FontFaceObserver from 'fontfaceobserver'
import { isShowcaseDomain } from 'Helpers/domain'
import { initTrackers } from 'Helpers/trackers/init'
import { mapState } from 'vuex'

export default {
  name: 'App',

  components: {
    LoaderFullScreen,
  },

  data() {
    return {
      isLoadingFonts: true,
    }
  },

  computed: {
    ...mapState([
      'isGlobalLoading',
    ]),

    isLoading() {
      return this.isGlobalLoading || this.isLoadingFonts
    },
  },

  watch: {
    isGlobalLoading: {
      async handler(newValue) {
        if (!newValue) {
          await initTrackers()
        }
      },

      immediate: true,
    },
  },

  created() {
    this.init()
  },

  methods: {
    async init() {
      await this.initFonts()
    },

    async initFonts() {
      await document.fonts.ready

      const fontawesome = new FontFaceObserver('Font Awesome 5 Free')

      await fontawesome.load()

      this.isLoadingFonts = false
    },
  },

  metaInfo: {
    titleTemplate: (titleChunk) => {
      if (isShowcaseDomain) {
        return titleChunk || 'Академия IBLS'
      }

      return titleChunk ? `${titleChunk} | IBLS` : `IBLS`
    },
  },
}
</script>

<style lang="scss">
@import '~@frontend/app';
</style>
