var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "loader-circle" }, [
    _c(
      "svg",
      {
        attrs: {
          xmlns: "http://www.w3.org/2000/svg",
          width: "150px",
          height: "150px",
          viewBox: "0 0 100 100",
          preserveAspectRatio: "xMidYMid",
        },
      },
      [
        _c("circle", {
          attrs: {
            cx: "50",
            cy: "50",
            fill: "none",
            "stroke-width": "1",
            r: "35",
            "stroke-dasharray": "164.93361431346415 56.97787143782138",
          },
        }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }