var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "loader-full-screen" }, [
      _c("div", { staticClass: "loader-full-screen__inner" }, [
        _c("div", { staticClass: "loader-full-screen__dot" }),
        _vm._v(" "),
        _c("div", { staticClass: "loader-full-screen__dot" }),
        _vm._v(" "),
        _c("div", { staticClass: "loader-full-screen__dot" }),
        _vm._v(" "),
        _c("div", { staticClass: "loader-full-screen__dot" }),
        _vm._v(" "),
        _c("div", { staticClass: "loader-full-screen__dot" }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }