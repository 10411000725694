// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TheHeaderMenuLink__linkWithChildren__H88f {\n  background-color: rgba(243, 243, 243, 0.5);\n}", "",{"version":3,"sources":["webpack://./webpack/@frontend/layouts/common/TheHeaderMenuLink.vue"],"names":[],"mappings":"AACA;EACE,0CAAA;AAAF","sourcesContent":["\n.linkWithChildren {\n  background-color: rgba(#f3f3f3, 0.5);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"linkWithChildren": "TheHeaderMenuLink__linkWithChildren__H88f"
};
export default ___CSS_LOADER_EXPORT___;
