<template>
  <div class="s-header__credit">
    <div class="credit__row">
      <span class="credit__key"> credit: </span>

      <span class="credit__value">{{ creditValue }}</span>
    </div>

    <div class="credit__row">
      <span class="credit__key"> max: </span>

      <span class="credit__value">{{ maxCredit }}</span>
    </div>

    <div class="credit__row">
      <span class="credit__key"> date: </span>

      <span class="credit__value">{{ dateFormatted }}</span>
    </div>
  </div>
</template>

<script>
import { getDate } from 'Helpers/dateFormatHelper'

export default {
  name: 'TheHeaderCredit',

  props: {
    credit: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    dateFormatted() {
      return getDate(this.credit.date)
    },

    creditValue() {
      return this.credit.credit
    },

    maxCredit() {
      return this.credit.maxCredit
    },
  },
}
</script>

<style lang="scss">
@import '~@frontend/scss/variables';

.credit {
  &__row {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;

    &--list {
      width: 100%;
      flex: 1;
    }
  }

  &__item {
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }

  &__key {
    margin-right: 5px;
    font-size: 12px;
    line-height: 18px;
    color: rgba($white, 0.48);
  }

  &__value {
    font-size: 16px;
    line-height: 20px;
    color: $white;
  }
}
</style>
