var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { class: _vm.$style.promoBlock }, [
    _c("header", { staticClass: "b-header" }, [
      _c("h2", { staticClass: "h1" }, [_vm._v(_vm._s(_vm.t("header")))]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass:
          "d-flex flex-wrap justify-content-sm-start justify-content-center",
      },
      [
        _c(
          "div",
          { staticClass: "product d-flex flex-wrap mr-0 mb-0 mb-md-4 mr-sm-4" },
          _vm._l(_vm.promoProducts, function (product) {
            return _c("Product", {
              key: product.id,
              attrs: { product: product },
            })
          }),
          1
        ),
        _vm._v(" "),
        _c("div", [
          _c(
            "div",
            {
              staticClass:
                "mb-3 mb-sm-0 h-100 w-100 d-flex flex-column align-items-center justify-content-center",
            },
            [
              _c(
                "ElButton",
                {
                  staticClass: "h-auto",
                  on: { click: _vm.onClickBtnLogClicksPromoMaterials },
                },
                [
                  _c("img", {
                    staticClass: "d-block mx-auto mb-2 iconShowMore",
                    attrs: { src: _vm.icons.showMore, alt: _vm.t("show_more") },
                  }),
                  _vm._v(" "),
                  _c(
                    "span",
                    { staticClass: "font-weight-medium font-size-16" },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.t("show_more")) +
                          "\n          "
                      ),
                    ]
                  ),
                ]
              ),
            ],
            1
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }