var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("v-select", {
    attrs: {
      options: _vm.options,
      clearable: false,
      searchable: false,
      dir: "ltr",
      label: "name",
    },
    scopedSlots: _vm._u([
      {
        key: "selected-option",
        fn: function (option) {
          return [
            _c("img", {
              attrs: {
                src: _vm.icons.language,
                alt: _vm.$t("layout.common.language_icon"),
              },
            }),
            _vm._v(" "),
            _c("span", { staticClass: "ml-2" }, [_vm._v(_vm._s(option.name))]),
          ]
        },
      },
    ]),
    model: {
      value: _vm.lang,
      callback: function ($$v) {
        _vm.lang = $$v
      },
      expression: "lang",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }