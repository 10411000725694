import ApiBase from 'ApiRest/ApiBase'

export const ApiSendFeRequestEvent = new (class extends ApiBase {
  /**
   * Триггер незаполненной заявки
   *
   * @returns {Promise}
   */
  post() {
    return this._POST('/add-fe-request-event')
  }
})()
