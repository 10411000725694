// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PromoSubjectItemPreview__promoCtp__PWws:not(:last-of-type) {\n  margin-bottom: 16px;\n}\n.PromoSubjectItemPreview__promoCtp__PWws [class~=home__subject]:hover {\n  color: inherit;\n  opacity: 0.48;\n}", "",{"version":3,"sources":["webpack://./webpack/@frontend/components/common/promo/PromoSubjectItemPreview.vue"],"names":[],"mappings":"AAEE;EACE,mBAAA;AADJ;AAIE;EACE,cAAA;EACA,aAAA;AAFJ","sourcesContent":["\n.promoCtp {\n  &:not(:last-of-type) {\n    margin-bottom: 16px;\n  }\n\n  [class~='home__subject']:hover {\n    color: inherit;\n    opacity: 0.48;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"promoCtp": "PromoSubjectItemPreview__promoCtp__PWws"
};
export default ___CSS_LOADER_EXPORT___;
