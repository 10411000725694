var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "s-header__credit" }, [
    _c("div", { staticClass: "credit__row" }, [
      _c("span", { staticClass: "credit__key" }, [_vm._v(" credit: ")]),
      _vm._v(" "),
      _c("span", { staticClass: "credit__value" }, [
        _vm._v(_vm._s(_vm.creditValue)),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "credit__row" }, [
      _c("span", { staticClass: "credit__key" }, [_vm._v(" max: ")]),
      _vm._v(" "),
      _c("span", { staticClass: "credit__value" }, [
        _vm._v(_vm._s(_vm.maxCredit)),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "credit__row" }, [
      _c("span", { staticClass: "credit__key" }, [_vm._v(" date: ")]),
      _vm._v(" "),
      _c("span", { staticClass: "credit__value" }, [
        _vm._v(_vm._s(_vm.dateFormatted)),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }