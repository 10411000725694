var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.isRendered.bannersBlock
        ? _c("BannersBlock", {
            staticClass: "mb-2 d-block d-lg-none",
            attrs: { banners: _vm.banners },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isRendered.promoBlock
        ? _c("PromoBlock", {
            staticClass: "mb-2 d-block d-lg-none",
            attrs: { "promo-products": _vm.promoProducts },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("section", { class: _vm.tabClass }, [
        _vm.hasSubjects
          ? _c("div", [
              _vm.educationType.fullTime
                ? _c(
                    "div",
                    {
                      staticClass: "home__subjects-block home__title-full-time",
                    },
                    [
                      _c("div", { staticClass: "form-row" }, [
                        _c("div", { staticClass: "col-8" }, [
                          _c("span", { staticClass: "t-tag" }, [
                            _vm._v(_vm._s(_vm.t("subject"))),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-4 text-right" }, [
                          _c("span", { staticClass: "t-tag" }, [
                            _vm._v(_vm._s(_vm.t("point"))),
                          ]),
                        ]),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.subjectsRequired.length
                ? _c(
                    "div",
                    { staticClass: "home__subjects-block" },
                    [
                      _vm._l(_vm.subjectsRequired, function (subject) {
                        return _c("SubjectItemPreview", {
                          key: subject.group.id,
                          attrs: { subject: subject },
                        })
                      }),
                      _vm._v(" "),
                      _vm.isRendered.promoCtps
                        ? _vm._l(_vm.promoCtps, function (promoCtp) {
                            return _c("PromoSubjectItemPreview", {
                              key: _vm.uniqueId(promoCtp.id),
                              attrs: { "promo-ctp": promoCtp },
                            })
                          })
                        : _vm._e(),
                    ],
                    2
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.subjectsOptional.length
                ? _c(
                    "div",
                    { staticClass: "home__subjects-block" },
                    _vm._l(_vm.subjectsOptional, function (subject) {
                      return _c("SubjectItemPreview", {
                        key: subject.group.id,
                        attrs: { subject: subject },
                      })
                    }),
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.subjectsAdditional.length
                ? _c(
                    "div",
                    { staticClass: "home__subjects-block" },
                    _vm._l(_vm.subjectsAdditional, function (subject) {
                      return _c("SubjectItemPreview", {
                        key: subject.group.id,
                        attrs: { subject: subject },
                      })
                    }),
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.subjectsExtracurricular.length
                ? _c(
                    "div",
                    { staticClass: "home__subjects-block" },
                    [
                      _c("div", { staticClass: "t-tag mb-2" }, [
                        _vm._v("Мои курсы"),
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.subjectsExtracurricular, function (subject) {
                        return _c("SubjectItemPreview", {
                          key: subject.group.id,
                          attrs: { subject: subject },
                        })
                      }),
                    ],
                    2
                  )
                : _vm._e(),
            ])
          : _c("div", { staticClass: "b-white__side" }, [
              _c("div", { staticClass: "text-placeholder text-gray" }, [
                _vm._v(
                  "\n        " + _vm._s(_vm.t("no_subjects")) + "\n      "
                ),
              ]),
            ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }