var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "picture",
    { class: _vm.$style.wrapper },
    [
      _vm.isRendered.default
        ? _c("source", { attrs: { srcset: _vm.url.default, alt: _vm.alt } })
        : _vm._e(),
      _vm._v(" "),
      _vm.isRendered.breakpoints
        ? [
            _vm.isRendered.mobile
              ? _c("source", {
                  attrs: {
                    srcset: _vm.url.mobile,
                    alt: _vm.alt,
                    media: "(max-width: 576px)",
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.isRendered.tablet
              ? _c("source", {
                  attrs: {
                    srcset: _vm.url.tablet,
                    alt: _vm.alt,
                    media: "(max-width: 992px)",
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.isRendered.desktop
              ? _c("source", {
                  attrs: {
                    srcset: _vm.url.desktop,
                    alt: _vm.alt,
                    media: "(min-width: 993px)",
                  },
                })
              : _vm._e(),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c("img", {
        class: _vm.$style.image,
        attrs: { src: _vm.src, alt: _vm.alt, loading: _vm.loading },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }