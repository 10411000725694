<template>
  <ElModal
    :name="name"
    :width="600"
    :adaptive="true"
    transition="none"
    class="modal-content"
  >
    <div class="vm--modal-content">
      <ElButton class="btn-img vm--modal-close" @click="onClickBtnClose">
        <img :src="icons.close" alt="icon close" />
      </ElButton>

      <div>
        <!-- TODO: i18n -->
        <h3 class="h3 text-dark">
          Для перехода в личный кабинет клиента введите пароль пользователя
          <a :href="hrefClientEmail">{{ profile.clientEmail }}</a>
        </h3>

        <form @submit.prevent="onSubmitForm">
          <ElInput
            :value="profile.clientEmail"
            autocomplete="username"
            class="d-none"
          />

          <ElInputPassword
            v-model="$v.formClientPassword.$model"
            :invalid="$v.formClientPassword.$error"
            autocomplete="current-password"
            autofocus
          />

          <div class="d-flex justify-content-between">
            <!-- TODO: i18n -->
            <ElButton
              :is-loading="isLoading.btnGo"
              :disabled="isDisabled.btnGo"
              type="submit"
              label="Перейти"
              class="btn-primary"
            />

            <!-- TODO: i18n -->
            <ElButton
              label="Отменить"
              class="btn-danger"
              @click="onClickBtnCancel"
            />
          </div>
        </form>
      </div>
    </div>
  </ElModal>
</template>

<script>
import ElButton from '@frontend/ui/ElButton.vue'
import ElInput from '@frontend/ui/ElInput.vue'
import ElInputPassword from '@frontend/ui/ElInputPassword.vue'
import ElModal from '@frontend/ui/ElModal.vue'
import { authUrl } from 'Helpers/const/authUrl'
import { showErrors } from 'Helpers/toast'
import iconClose from 'Static/images/@frontend/common-lk/icons/close.svg'
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapMutations, mapState } from 'vuex'

export default {
  name: 'ModalClientPassword',

  components: {
    ElInput,
    ElModal,
    ElButton,
    ElInputPassword,
  },

  data() {
    return {
      name: 'modalClientPassword',

      icons: {
        close: iconClose,
      },

      isLoading: {
        btnGo: false,
      },
    }
  },

  computed: {
    ...mapState('student/profile', [
      'profile',
      'clientPassword',
      'clientToken',
    ]),

    hrefClientEmail() {
      return `mailto: ${this.profile.clientEmail}`
    },

    hrefClient() {
      const url = new URL(authUrl.userSwitch)

      if (this.clientToken) {
        url.searchParams.append('token', this.clientToken)
      }

      return url.href
    },

    isDisabled() {
      return {
        btnGo: this.$v.$invalid,
      }
    },

    /**
     * @public
     */
    formClientPassword: {
      get() {
        return this.clientPassword
      },

      set(value) {
        this.setClientPassword(value)
      },
    },
  },

  validations: {
    formClientPassword: {
      required,
    },
  },

  methods: {
    ...mapMutations('student/profile', [
      'setClientPassword',
    ]),

    ...mapActions('student/profile', [
      'fetchClientToken',
    ]),

    async switchToClient() {
      try {
        await this.fetchClientToken()

        window.location.href = this.hrefClient
      } catch (error) {
        showErrors(error)
      }
    },

    async onSubmitForm() {
      this.isLoading.btnGo = true
      await this.switchToClient()
      this.isLoading.btnGo = false
    },

    onClickBtnClose() {
      this.hide()
    },

    onClickBtnCancel() {
      this.hide()
    },

    /**
     * @public
     */
    show() {
      this.$modal.show(this.name)
    },

    /**
     * @public
     */
    hide() {
      this.$modal.hide(this.name)
    },
  },
}
</script>
