var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "ElModal",
    {
      staticClass: "modal-content",
      attrs: {
        name: _vm.name,
        width: 700,
        adaptive: true,
        "click-to-close": false,
        transition: "none",
      },
    },
    [
      _c("div", { staticClass: "vm--modal-content" }, [
        _c("h3", { staticClass: "h3" }, [_vm._v(_vm._s(_vm.title))]),
        _vm._v(" "),
        _c("p", { domProps: { innerHTML: _vm._s(_vm.description) } }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "mb-3", class: _vm.$style.cards },
          _vm._l(_vm.products, function (product) {
            return _c("ProductCard", {
              key: `${product.id}-${product.offer.id}`,
              attrs: {
                product: product,
                "is-selected": _vm.isSelected.product(product),
              },
              on: {
                click: function ($event) {
                  return _vm.onClickProductCard(product)
                },
              },
            })
          }),
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "d-flex flex-column" },
          [
            _c("ElButton", {
              staticClass: "btn-primary w-100 mb-2",
              attrs: {
                label: _vm.labels.btnBuy,
                disabled: _vm.isDisabled.btnBuy,
              },
              on: { click: _vm.onClickBtnBuy },
            }),
            _vm._v(" "),
            _vm.isRendered.btnShowAllOffers
              ? _c(
                  "a",
                  {
                    staticClass: "btn btn-outline-primary w-100 mb-2",
                    attrs: { href: _vm.linkShowAllOffers },
                    on: { click: _vm.onClickBtnShowAllOffers },
                  },
                  [_vm._v("Показать все предложения")]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass:
                  "align-self-center d-block text-center text-underline cursor-pointer mt-2 mb-n2",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.onClickBtnNotInterested.apply(null, arguments)
                  },
                },
              },
              [_vm._v("Мне это неинтересно")]
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }