var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "btn-up",
      class: { visible: _vm.visible },
      on: { click: _vm.upHandler },
    },
    [
      _c(
        "svg",
        {
          attrs: {
            width: "16",
            height: "15",
            viewBox: "0 0 16 15",
            fill: "none",
            xmlns: "http://www.w3.org/2000/svg",
          },
        },
        [
          _c("path", {
            attrs: {
              d: "M2.39607 7.70978L8 2L13.6569 7.65685",
              stroke: "#332F3E",
              "stroke-width": "2",
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
            },
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }