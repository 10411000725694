<template>
  <div class="cursor-pointer" :class="$style.promoCtp">
    <div class="home__subject" @click="onClickOpenModalPromo">
      <div class="form-row">
        <div class="subject__name col-12">
          <i class="fa fa-lock mr-2"></i>

          <span>{{ name }}</span>
        </div>
      </div>
    </div>

    <ModalPromoMaterials
      ref="modalPromoMaterials"
      :promo-campaign-id="promoCampaignId"
      :promo-action-log-type-id="promoActionLogTypeId"
    />
  </div>
</template>

<script>
import ModalPromoMaterials from '@frontend/components/common/promo/ModalPromoMaterials.vue'
import { promoActionsClick } from '@frontend/helpers/promoActions'
import Vue from 'vue'
import { mapActions } from 'vuex'

export default Vue.extend({
  name: 'PromoSubjectItemPreview',

  components: {
    ModalPromoMaterials,
  },

  props: {
    promoCtp: {
      type: Object,
      required: true,
    },
  },

  computed: {
    name() {
      return this.promoCtp.name
    },

    promoActionLogTypeId() {
      return promoActionsClick.modalCtp
    },

    promoCampaignId() {
      return this.promoCtp.promoCampaign.id
    },
  },

  methods: {
    ...mapActions('common/promoCampaign', [
      'logClicksPromoMaterials',
    ]),

    async onClickOpenModalPromo() {
      await this.logClicksPromoMaterials({
        promoActionLogTypeId: promoActionsClick.ctpSubjects,
        product: {
          id: this.promoCtp.promoProduct.id,
        },
      })

      this.$refs.modalPromoMaterials.show()
    },
  },
})
</script>

<style lang="scss" module>
.promoCtp {
  &:not(:last-of-type) {
    margin-bottom: 16px;
  }

  [class~='home__subject']:hover {
    color: inherit;
    opacity: 0.48;
  }
}
</style>
