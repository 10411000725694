var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "form-group",
      class: { "is-invalid": _vm.invalid, "is-loading": _vm.isLoading },
    },
    [
      _c("div", { staticClass: "d-flex align-items-center" }, [
        _vm.label
          ? _c("label", { staticClass: "form-label" }, [
              _vm._v("\n      " + _vm._s(_vm.label) + " "),
              _vm.required
                ? _c("i", { staticClass: "text-pink" }, [_vm._v("*")])
                : _vm._e(),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.hint
          ? _c("i", {
              directives: [
                {
                  name: "tippy",
                  rawName: "v-tippy",
                  value: { placement: "right", arrow: true },
                  expression: "{ placement: 'right', arrow: true }",
                },
              ],
              staticClass: "form-hint fas fa-question-circle",
              attrs: { content: _vm.hint },
            })
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "position-relative" },
        [
          _vm.isMasked
            ? _c(
                "ImaskInput",
                _vm._b(
                  {
                    ref: "input",
                    class: _vm.cssClassInput,
                    attrs: {
                      disabled: _vm.disabled,
                      mask: _vm.mask,
                      type: _vm.type,
                    },
                    model: {
                      value: _vm.modelValue,
                      callback: function ($$v) {
                        _vm.modelValue = $$v
                      },
                      expression: "modelValue",
                    },
                  },
                  "ImaskInput",
                  _vm.$attrs,
                  false
                )
              )
            : _vm.type === "checkbox"
            ? _c(
                "input",
                _vm._b(
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.modelValue,
                        expression: "modelValue",
                      },
                    ],
                    ref: "input",
                    class: _vm.cssClassInput,
                    attrs: { disabled: _vm.disabled, type: "checkbox" },
                    domProps: {
                      checked: Array.isArray(_vm.modelValue)
                        ? _vm._i(_vm.modelValue, null) > -1
                        : _vm.modelValue,
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.modelValue,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 && (_vm.modelValue = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.modelValue = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.modelValue = $$c
                        }
                      },
                    },
                  },
                  "input",
                  _vm.$attrs,
                  false
                )
              )
            : _vm.type === "radio"
            ? _c(
                "input",
                _vm._b(
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.modelValue,
                        expression: "modelValue",
                      },
                    ],
                    ref: "input",
                    class: _vm.cssClassInput,
                    attrs: { disabled: _vm.disabled, type: "radio" },
                    domProps: { checked: _vm._q(_vm.modelValue, null) },
                    on: {
                      change: function ($event) {
                        _vm.modelValue = null
                      },
                    },
                  },
                  "input",
                  _vm.$attrs,
                  false
                )
              )
            : _c(
                "input",
                _vm._b(
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.modelValue,
                        expression: "modelValue",
                      },
                    ],
                    ref: "input",
                    class: _vm.cssClassInput,
                    attrs: { disabled: _vm.disabled, type: _vm.type },
                    domProps: { value: _vm.modelValue },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.modelValue = $event.target.value
                      },
                    },
                  },
                  "input",
                  _vm.$attrs,
                  false
                )
              ),
          _vm._v(" "),
          _vm.isLoading
            ? _c("span", {
                staticClass: "spinner-border spinner-border-sm",
                attrs: { "aria-hidden": "true", role: "status" },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.hasAppendIcon
            ? _c(
                "div",
                {
                  class: _vm.cssClassInputAppendIcon,
                  on: { click: _vm.onClickAppendIcon },
                },
                [
                  _vm._t("appendIcon", function () {
                    return [
                      _c("img", {
                        attrs: { src: _vm.icons.search, alt: "icon search" },
                      }),
                    ]
                  }),
                ],
                2
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "form-invalid" }, [_vm._t("invalid")], 2),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }