import ApiBase from 'ApiRest/ApiBase'

export const ApiCoursesPurchaseGenerateInvoices = new (class extends ApiBase {
  /**
   * Обновление данных клиента в заказе
   *
   * @param {number} purchaseId
   * @returns {Promise}
   */
  put(purchaseId) {
    const url = `/courses/purchase/${purchaseId}/generate-invoices`

    return this._PUT(url)
  }
})()
