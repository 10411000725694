<template>
  <div
    class="card cursor-pointer"
    :class="$style.productCard"
    @click="onClickLogClicksPromoMaterials"
  >
    <div
      class="card-body overflow-hidden p-3 pb-sm-4 d-flex align-items-start flex-sm-column"
    >
      <div :class="$style.image" class="d-block d-sm-none">
        <ElImage
          :image-id="image.id"
          :src="image.src"
          :alt="image.alt"
          :breakpoints="image.breakpoints"
          loading="lazy"
        />
      </div>

      <div class="d-flex flex-column ml-3 ml-sm-0 h-100">
        <div class="px-0">
          <span class="btn-tag btn-tag-blue">
            {{ product.type.name }}
          </span>
        </div>

        <div class="d-flex flex-wrap-reverse flex-sm-wrap">
          <div class="h4 mt-2 mt-sm-3 mb-0 text-body w-100">
            {{ product.name }}
          </div>

          <div
            v-if="dateInfoText"
            class="mt-2 text-secondary font-weight-medium w-100"
          >
            {{ dateInfoText }}
          </div>
        </div>

        <div class="d-flex flex-column justify-content-end flex-grow-1">
          <div :class="$style.image" class="d-none d-sm-block mt-3">
            <ElImage
              :class="$style.imageContainer"
              :image-id="image.id"
              :src="image.src"
              :alt="image.alt"
              loading="lazy"
            />
          </div>

          <div
            :class="$style.minPrice"
            class="mt-2 mt-sm-3 font-weight-normal text-body"
          >
            {{ minPriceText }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { promoActionsClick } from '@frontend/helpers/promoActions'
import ElImage from '@frontend/ui/ElImage.vue'
import {
  formatDistanceStrict,
  getDate as getDateHelper,
  parseISO,
} from 'date-fns'
import ru from 'date-fns/locale/ru'
import { getDate } from 'Helpers/dateFormatHelper'
import { numberToCurrency } from 'Helpers/numberToCurrency'
import { capitalize } from 'lodash'
import imageLogo from 'Static/images/productCover.jpg'
import Vue from 'vue'
import { mapActions, mapGetters, mapState } from 'vuex'

export default Vue.extend({
  name: 'Product',

  components: {
    ElImage,
  },

  props: {
    product: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapState('common/user', [
      'role',
    ]),

    ...mapGetters('common/user', [
      'getRouteNameOnUser',
    ]),

    // TODO: i18n
    dateInfoText() {
      const { startDate, endDate, timeStart, timeEnd } = this.product

      if (this.product.displayOnlyMonth && startDate) {
        const start = this.getMonthName(startDate)

        if (endDate) {
          const end = this.getMonthName(endDate)

          return start === end ? start : `${start} - ${end}`
        }

        return start
      }

      if (startDate) {
        const startDateDay = getDateHelper(parseISO(startDate))

        const startDateTextPrefix = startDateDay === 2 ? 'со' : 'c'
        const startDateText = getDate(startDate, 'd MMMM')

        let text = `${startDateTextPrefix} ${startDateText}`

        if (endDate) {
          const getDateWithDefaultTime = (date) => parseISO(`${date} 00:00`)

          const startDateTime = timeStart
            ? parseISO(`${startDate} ${timeStart}`)
            : getDateWithDefaultTime(startDate)

          const endDateTime = timeEnd
            ? parseISO(`${endDate} ${timeEnd}`)
            : getDateWithDefaultTime(endDate)

          const durationText = formatDistanceStrict(
            startDateTime,
            endDateTime,
            {
              locale: ru,
            },
          )

          text = `${durationText}, ${text}`
        }

        return text
      }

      return null
    },

    // TODO: i18n
    minPriceText() {
      const { offers } = this.product

      if (offers.length > 0) {
        const prices = offers.map(({ price }) => price)
        const price = Math.min(...prices)

        if (price === 0) {
          return 'Бесплатно'
        }

        if (offers.length === 1) {
          return numberToCurrency(price)
        }

        return `от ${numberToCurrency(price)}`
      }

      return ''
    },

    image() {
      return {
        src: this.product.image?.url ?? imageLogo,
        id: this.product.image?.id,
        alt: this.product.name ?? 'cover',
        breakpoints: {
          mobile: 130,
        },
      }
    },

    isShowcase() {
      return this.product.isShowcase
    },
  },

  methods: {
    ...mapActions('common/promoCampaign', [
      'logClicksPromoMaterials',
    ]),

    async onClickLogClicksPromoMaterials() {
      await this.logClicksPromoMaterials({
        promoActionLogTypeId: promoActionsClick.productCardMainPage,
        product: {
          id: this.product.id,
        },
      })

      if (this.isShowcase) {
        const { protocol } = window.location
        const { showcase } = window.domains
        const { mainCategory, seo } = this.product

        const urlProductShowcase = `${protocol}//${showcase}/${this.role}/category/${mainCategory.seo.prettyLink}/${seo.prettyLink}`

        window.location.href = urlProductShowcase
      } else {
        await this.$router.push({
          name: this.getRouteNameOnUser('courses-product'),
          params: {
            productId: this.product.id,
          },
        })
      }
    },

    getMonthName(date) {
      return capitalize(getDate(date, 'LLLL'))
    },
  },
})
</script>

<style lang="scss" module>
@import '~@frontend/scss/variables';

$collection_height: 8px;
$collection_height_2x: $collection_height * 2;

.productCard {
  position: relative;
  width: var(--showcase-product-width);

  @media (max-width: $sm) {
    width: 100%;
    border-radius: 0;
  }
}

.imageContainer {
  $padding: 16px;
  display: flex;
  width: calc(100% + #{$padding * 2});
  margin: 0 -#{$padding};

  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    margin-bottom: 0;
  }
}

.image {
  @media (max-width: $sm) {
    flex: 0 0 30%;

    img {
      margin: 0;
      overflow: hidden;
      border-radius: 8px;
    }
  }
}

.minPrice {
  font-size: 20px;
  line-height: 26px;
}
</style>
