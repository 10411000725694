import ApiBase from 'ApiRest/ApiBase'
import UrlHelper from 'Helpers/UrlHelper'

/**
 * @typedef CalendarItemI
 * ```
 * {{
 * id: number,
 * type: {
 *   id: number,
 *   name: string
 * },
 * name: string,
 * dateStart: string,
 * dateEnd: string|null,
 * timeStart: string|null,
 * timeEnd: string|null,
 * extraData: {
 *   manager: {
 *     id: number,
 *     firstName: string|null,
 *     lastName: string|null,
 *     middleName: string|null
 *   }|null|undefined,
 *   studentCount: number|undefined,
 *   subject: {
 *     id: number,
 *     name: string|null
 *   }|null|undefined
 * }|null|undefined
 * }}
 */

export default new (class extends ApiBase {
  /**
   * @param {Object} args
   * @param {string} args.start
   * @param {string|null} args.end
   * @param {number|null} args.studentId
   * @param {number|null} args.schoolId
   * @param {number[]|null} args.gradeIds
   * @param {number[]|null} args.managerIds
   * @param {number[]|null} args.subjectIds
   * @param {boolean} args.emptyTheme
   * @param {boolean} args.onlyMineLessons
   * @param {number[]|null} args.typeIds
   * @param args.schoolIds
   * @returns {Promise<{data: CalendarItemI[]}>}
   */
  get({
    start,
    end = null,
    studentId = null,
    schoolIds = null,
    gradeIds = null,
    managerIds = null,
    subjectIds = null,
    emptyTheme = false,
    onlyMineLessons = false,
    typeIds = null,
  }) {
    const parameters = {
      periodStart: start,
      periodEnd: end || start,
    }

    if (emptyTheme) {
      parameters.emptyTheme = true
    }

    if (onlyMineLessons) {
      parameters.onlyMineLessons = true
    }

    if (studentId) {
      parameters.student = {
        id: studentId,
      }
    }

    /**
     * @param {number[]} list
     * @param {string} field
     */
    const fillIds = (list, field) => {
      if (list?.length > 0) {
        parameters[field] = []
        list.forEach((id) => {
          parameters[field].push({
            id,
          })
        })
      }
    }

    fillIds(schoolIds, 'schools')
    fillIds(gradeIds, 'grades')
    fillIds(managerIds, 'managers')
    fillIds(subjectIds, 'subjects')
    fillIds(typeIds, 'calendarItemTypes')

    return this._GET(`/calendar/items?${UrlHelper.param(parameters)}`)
  }
})()
