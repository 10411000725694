var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "cursor-pointer", class: _vm.$style.promoCtp },
    [
      _c(
        "div",
        {
          staticClass: "home__subject",
          on: { click: _vm.onClickOpenModalPromo },
        },
        [
          _c("div", { staticClass: "form-row" }, [
            _c("div", { staticClass: "subject__name col-12" }, [
              _c("i", { staticClass: "fa fa-lock mr-2" }),
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(_vm.name))]),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("ModalPromoMaterials", {
        ref: "modalPromoMaterials",
        attrs: {
          "promo-campaign-id": _vm.promoCampaignId,
          "promo-action-log-type-id": _vm.promoActionLogTypeId,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }