import { cloneDeep, isArray, isNil, isObject, mergeWith } from 'lodash'

class VueHelper {
  /**
   * Get external data transferred
   * to Vue instance by data-attribute
   * @param {string} el - CSS selector of Vue application
   * @returns {Object} data
   */
  static getApplicationProps(el) {
    const vue = document.querySelector(el)
    const data = vue ? vue.getAttribute('data-application-props') : ''

    if (data === '') {
      return {}
    }

    return JSON.parse(data)
  }

  /**
   * Combine data with data-object blueprint
   * (_.defaultsDeep() which also overrides null values)
   * See https://github.com/lodash/lodash/issues/2499
   * @param {Object} destination
   * @param {Object} source
   * @returns {Object}
   */
  static defaultsData(destination, source = {}) {
    if (!source || !Object.keys(source).length) {
      return destination
    }

    const nilMerge = (a, b) => (isNil(a) ? b : a)
    const nilMergeDeep = (a, b) =>
      isObject(a) && !isArray(a)
        ? mergeWith({}, a, b, nilMergeDeep)
        : nilMerge(a, b)

    const sourceClone = cloneDeep(source)

    mergeWith(destination, sourceClone, nilMergeDeep)

    return destination
  }
}

export default VueHelper
