import ApiBase from 'ApiRest/ApiBase'

export default new (class extends ApiBase {
  /**
   * Получить Очный КТП-Тест по ID
   *
   * @param {number} fteCtpTestId
   * @returns {Promise}
   */
  get(fteCtpTestId) {
    return this._GET(`/account/student/test/lesson-tests/${fteCtpTestId}`)
  }
})()
