import 'formdata-polyfill'

import HttpStatusCodes from 'Api/const/HttpStatusCodes'
import UploadException from 'Api/exceptions/Upload'
import AxiosHelper from 'Helpers/AxiosHelper'

const REST_URL = `${window.domains.apiUrl}/rest`
const BASE_UPLOAD_URL = `${window.domains.apiUrl}/upload`

export default new (class Upload extends AxiosHelper {
  constructor() {
    super()
    this.documentUrl = `${BASE_UPLOAD_URL}/document`
    this.fileUrl = `${BASE_UPLOAD_URL}/file`
    this.filePrivateUrl = `${BASE_UPLOAD_URL}/private-file`
    this.imageUrl = `${BASE_UPLOAD_URL}/image`
    this.videoUrl = `${BASE_UPLOAD_URL}/video`
  }

  /**
   * @param {Object} error
   * @throws {UploadException}
   * @private
   */
  // TODO: i18n
  static _error(error) {
    if (!error.response) {
      throw new UploadException('Ошибка отправки файла')
    }

    const { data, status } = error.response

    if (data[0] && data[0].message) {
      throw new UploadException(data[0].message)
    }

    if (status === HttpStatusCodes.UnprocessableEntity) {
      throw new UploadException('Тип файла запрещен для загрузки')
    }

    if (status === HttpStatusCodes.PayloadTooLarge) {
      throw new UploadException('Размер файла слишком большой')
    }
  }

  /**
   * @param {string} url
   * @param {Blob} file
   * @param {number} originalFileId
   * @param {Function} onUploadProgress
   * @returns {Promise}
   * @private
   */
  _send(url, file, onUploadProgress = function () {}, originalFileId = null) {
    const data = new FormData()

    data.set('file', file)

    if (originalFileId !== null) {
      data.set('originalFileId', String(originalFileId))
    }

    return this.POST(url, data, onUploadProgress).catch((error) =>
      Upload._error(error),
    )
  }

  /**
   * @param {Blob} document
   * @param {Function} onUploadProgress
   * @returns {Promise}
   */
  sendDocument(document, onUploadProgress = function () {}) {
    return this._send(this.documentUrl, document, onUploadProgress)
  }

  /**
   * @param {Blob} file
   * @param {Function} onUploadProgress
   * @param {number} originalFileId
   * @param {boolean} isPrivate
   * @returns {Promise}
   */
  sendFile(
    file,
    onUploadProgress = function () {},
    isPrivate = false,
    originalFileId = null,
  ) {
    const url = isPrivate === true ? this.filePrivateUrl : this.fileUrl

    return this._send(url, file, onUploadProgress, originalFileId)
  }

  /**
   * @param {Object} options
   * @param {string} options.url
   * @param {Blob} options.file
   * @returns {Promise}
   */
  sendFileToCustomEndpoint({ url, file }) {
    return this._send(url, file)
  }

  /**
   * @param {Blob} image
   * @param {Function} onUploadProgress
   * @returns {Promise}
   */
  sendImage(image, onUploadProgress = function () {}) {
    return this._send(this.imageUrl, image, onUploadProgress)
  }

  /**
   * Загрузка аватарки
   * @param {string} url
   * @param {Blob} file
   * @returns {Promise}
   */
  sendPhoto(url, file) {
    const resultUrl = `${REST_URL}${url}`

    return this._send(resultUrl, file)
  }

  sendVideo(video, onUploadProgress = function () {}) {
    return this._send(this.videoUrl, video, onUploadProgress)
  }
})()
