// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TheAsideLink__iconLinkExternal__b1FT {\n  position: absolute;\n  top: 11px;\n  right: 11px;\n}", "",{"version":3,"sources":["webpack://./webpack/@frontend/layouts/common/TheAsideLink.vue"],"names":[],"mappings":"AACA;EACE,kBAAA;EACA,SAAA;EACA,WAAA;AAAF","sourcesContent":["\n.iconLinkExternal {\n  position: absolute;\n  top: 11px;\n  right: 11px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iconLinkExternal": "TheAsideLink__iconLinkExternal__b1FT"
};
export default ___CSS_LOADER_EXPORT___;
