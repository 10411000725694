var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ElModal",
    {
      staticClass: "modal-content",
      attrs: { name: _vm.name, width: 600, adaptive: true, transition: "none" },
    },
    [
      _c(
        "div",
        { staticClass: "vm--modal-content" },
        [
          _c(
            "ElButton",
            {
              staticClass: "btn-img vm--modal-close",
              on: { click: _vm.onClickBtnClose },
            },
            [_c("img", { attrs: { src: _vm.icons.close, alt: "icon close" } })]
          ),
          _vm._v(" "),
          _c("div", [
            _c("h3", { staticClass: "h3 text-dark" }, [
              _vm._v(
                "\n        Для перехода в личный кабинет клиента введите пароль пользователя\n        "
              ),
              _c("a", { attrs: { href: _vm.hrefClientEmail } }, [
                _vm._v(_vm._s(_vm.profile.clientEmail)),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "form",
              {
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.onSubmitForm.apply(null, arguments)
                  },
                },
              },
              [
                _c("ElInput", {
                  staticClass: "d-none",
                  attrs: {
                    value: _vm.profile.clientEmail,
                    autocomplete: "username",
                  },
                }),
                _vm._v(" "),
                _c("ElInputPassword", {
                  attrs: {
                    invalid: _vm.$v.formClientPassword.$error,
                    autocomplete: "current-password",
                    autofocus: "",
                  },
                  model: {
                    value: _vm.$v.formClientPassword.$model,
                    callback: function ($$v) {
                      _vm.$set(_vm.$v.formClientPassword, "$model", $$v)
                    },
                    expression: "$v.formClientPassword.$model",
                  },
                }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "d-flex justify-content-between" },
                  [
                    _c("ElButton", {
                      staticClass: "btn-primary",
                      attrs: {
                        "is-loading": _vm.isLoading.btnGo,
                        disabled: _vm.isDisabled.btnGo,
                        type: "submit",
                        label: "Перейти",
                      },
                    }),
                    _vm._v(" "),
                    _c("ElButton", {
                      staticClass: "btn-danger",
                      attrs: { label: "Отменить" },
                      on: { click: _vm.onClickBtnCancel },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }