import ApiBase from 'ApiRest/ApiBase'

export const ApiShowcaseOnlineLessonCancel = new (class extends ApiBase {
  /**
   * Отменить запись на урок
   *
   * @param {number} onlineLessonId
   * @returns {Promise}
   */
  put(onlineLessonId) {
    return this._PUT(
      `/showcase/account/online-lessons/${onlineLessonId}/canceled`,
    )
  }
})()
