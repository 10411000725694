import ApiBase from 'ApiRest/ApiBase'

export const ApiShowcasePurchasedProducts = new (class extends ApiBase {
  /**
   * Получаем список купленных продуктов по id счёта
   *
   * @param {string} invoiceId
   * @returns {Promise}
   */
  get(invoiceId) {
    return this._GET(`/account/invoices/${invoiceId}/products`)
  }
})()
