/**
 * @param {string} src
 * @param {boolean} async
 */
export const loadScript = function (src, async = true) {
  const firstScript = document.getElementsByTagName('script')[0]
  const script = document.createElement('script')

  script.src = src
  script.async = async

  firstScript.parentNode.insertBefore(script, firstScript)
}
