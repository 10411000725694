var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ElModal",
    {
      staticClass: "modal-content",
      attrs: {
        name: _vm.name,
        width: 600,
        adaptive: true,
        "click-to-close": false,
        transition: "none",
      },
    },
    [
      _c(
        "div",
        { staticClass: "vm--modal-content" },
        [
          _c("h3", { staticClass: "h3 text-center" }, [
            _vm._v("Добро пожаловать в IBLS!"),
          ]),
          _vm._v(" "),
          _c("h3", { staticClass: "h3 text-center" }, [
            _vm._v("Ваша оплата прошла успешно."),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mb-3 text-center", class: _vm.$style.textDarkGray },
            [
              _vm._v(
                "\n      На указанную почту " +
                  _vm._s(_vm.email) +
                  " уже отправлена ссылка для подтверждения\n      аккаунта. Перейдите по ссылке из письма, иначе часть функций платформы\n      будет недоступна.\n    "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "text-center", class: _vm.$style.textDarkGray },
            [
              _vm._v(
                "\n      Если вы опечатались или у вас есть какие-либо другие вопросы,\n      пожалуйста, свяжитесь с нами по почте:\n    "
              ),
            ]
          ),
          _vm._v(" "),
          _c("ElLink", {
            staticClass: "text-center mb-3",
            class: _vm.$style.textDarkGray,
            attrs: { label: _vm.supportEmail, href: _vm.supportEmailHref },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "text-gray text-center mb-3" }, [
            _vm._v(
              '\n      Если вы не видите письмо, проверьте папку "Спам".\n    '
            ),
          ]),
          _vm._v(" "),
          _c("ElButton", {
            staticClass: "btn-primary mb-3",
            attrs: {
              disabled: _vm.isDisabledBtnSendConfirmEmail,
              label: "Отправить письмо ещё раз",
            },
            on: { click: _vm.onClickBtnSendConfirmEmail },
          }),
          _vm._v(" "),
          _vm.isRendered.countdown
            ? _c(
                "div",
                [
                  _c("Countdown", {
                    attrs: { time: _vm.resendCodeEmailTimeout },
                    on: { end: _vm.onStopCountdown },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ minutes, seconds }) {
                            return [
                              _c("p", { staticClass: "text-center mb-1" }, [
                                _vm._v(
                                  "\n          Повторная отправка возможна через " +
                                    _vm._s(minutes) +
                                    " минут\n          " +
                                    _vm._s(seconds) +
                                    " сек.\n        "
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1075244775
                    ),
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }