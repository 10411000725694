import ApiBase from 'ApiRest/ApiBase'

export const ApiShowcaseProductsByCategory = new (class extends ApiBase {
  /**
   * Получение продуктов по категории
   *
   * @param {string} category
   * @param {Object} params
   * @returns {Promise}
   */
  get(category, params = {}) {
    const url = `/showcase/products/${category}?${this.getUrlParams(params)}`

    return this._GET(url)
  }
})()
