import { render, staticRenderFns } from "./ModalWelcome.vue?vue&type=template&id=028d2c8a"
import script from "./ModalWelcome.vue?vue&type=script&lang=js"
export * from "./ModalWelcome.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/dev-gamma-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('028d2c8a')) {
      api.createRecord('028d2c8a', component.options)
    } else {
      api.reload('028d2c8a', component.options)
    }
    module.hot.accept("./ModalWelcome.vue?vue&type=template&id=028d2c8a", function () {
      api.rerender('028d2c8a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "webpack/@frontend/components/client/contract/ModalWelcome.vue"
export default component.exports