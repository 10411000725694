var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: [_vm.$style.avatar, _vm.sizeClass, _vm.shapeClass] },
    [
      _vm.image
        ? _c("img", { attrs: { src: _vm.image, alt: _vm.$t("ui.avatar") } })
        : _c("div", { class: _vm.$style.abbreviation }, [
            _vm._v(_vm._s(_vm.abbreviation)),
          ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }