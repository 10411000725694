import ApiDownloadFile from 'Api/Download'
import ApiOption from 'ApiRest/Api/Option'
import { ApiShowcasePurchase } from 'ApiRest/Api/Showcase/Purchase'
import { ApiShowcasePurchaseClientData } from 'ApiRest/Api/Showcase/Purchase/ClientData'
import { ApiShowcasePurchaseGenerateInvoices } from 'ApiRest/Api/Showcase/Purchase/GenerateInvoices'
import { ApiShowcasePurchaseItemDocument } from 'ApiRest/Api/Showcase/Purchase/Item/document/PurchaseItemDocument'
import { ApiShowcasePurchaseReceive } from 'ApiRest/Api/Showcase/Purchase/Receive'
import { ApiShowcasePurchaseStudent } from 'ApiRest/Api/Showcase/Purchase/Student'
import { ApiShowcasePurchaseStudents } from 'ApiRest/Api/Showcase/Purchase/Students'
import { ApiShowcasePurchases } from 'ApiRest/Api/Showcase/Purchases'
import DownloadHelper from 'Helpers/DownloadHelper'

export default {
  async fetchPurchases({ commit }) {
    const { data } = await ApiShowcasePurchases.get()

    commit('setPurchases', data)
  },

  async fetchPurchase({ commit, state }) {
    const { data } = await ApiShowcasePurchase.get(state.purchaseId)

    commit('setPurchase', data)
  },

  async fetchClientData({ commit, state }) {
    const { data } = await ApiShowcasePurchaseClientData.get(state.purchaseId)

    commit('setClientData', data)
  },

  async updateClientData({ commit, state }) {
    const { data } = await ApiShowcasePurchaseClientData.patch(
      state.purchaseId,
      state.customer,
    )

    commit('setClientData', data)
  },

  async fetchOptionCitizenshipList({ commit }) {
    const { data } = await ApiOption.getCitizenships()

    commit('setOptionValue', {
      path: 'citizenshipList',
      value: data,
    })
  },

  async fetchOptionCountryList({ commit }) {
    const { data } = await ApiOption.getCountries()

    commit('setOptionValue', {
      path: 'countryList',
      value: data,
    })
  },

  async fetchOptionKinshipList({ commit }) {
    const { data } = await ApiOption.getKinshipDegrees()

    commit('setOptionValue', {
      path: 'kinshipList',
      value: data,
    })
  },

  async fetchPurchaseItemDocument(
    context,
    { purchaseId, purchaseItemId, documentTemplateId },
  ) {
    const response = await ApiShowcasePurchaseItemDocument.get({
      purchaseId,
      purchaseItemId,
      documentTemplateId,
    })

    DownloadHelper.downloadBlob(response)
  },

  fetchSignedDocumentUrl(context, fileId) {
    return ApiDownloadFile.getFileUrl(fileId)
  },

  async createInvoice({ state, commit }) {
    const { data } = await ApiShowcasePurchaseGenerateInvoices.put(
      state.purchaseId,
    )

    commit('setInvoices', data)
  },

  async fetchStudents({ commit, state }) {
    const { data } = await ApiShowcasePurchaseStudents.get(state.purchaseId)

    commit('setStudents', data)
  },

  async updateStudent({ commit, state }, studentId) {
    const payload = state.students.find(
      ({ student }) => student.id === studentId,
    )

    const { data } = await ApiShowcasePurchaseStudent.patch({
      purchaseId: state.purchaseId,
      studentId,
      payload,
    })

    commit('setStudent', {
      studentId,
      payload: data,
    })
  },

  async receivePurchase({ commit, state }) {
    const { data } = await ApiShowcasePurchaseReceive.put(state.purchaseId)

    commit('setPurchase', data)
  },
}
