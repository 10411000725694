import { render, staticRenderFns } from "./StudentMain.vue?vue&type=template&id=4eceb971"
import script from "./StudentMain.vue?vue&type=script&lang=js"
export * from "./StudentMain.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/dev-gamma-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4eceb971')) {
      api.createRecord('4eceb971', component.options)
    } else {
      api.reload('4eceb971', component.options)
    }
    module.hot.accept("./StudentMain.vue?vue&type=template&id=4eceb971", function () {
      api.rerender('4eceb971', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "webpack/@frontend/layouts/StudentMain.vue"
export default component.exports