// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ModalConfirmEmail__textDarkGray__9sqm {\n  color: #2f383f;\n}", "",{"version":3,"sources":["webpack://./webpack/@frontend/components/common/ModalConfirmEmail.vue"],"names":[],"mappings":"AAGA;EACE,cAHU;AACZ","sourcesContent":["\n$dark-gray: #2f383f;\n\n.textDarkGray {\n  color: $dark-gray;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textDarkGray": "ModalConfirmEmail__textDarkGray__9sqm"
};
export default ___CSS_LOADER_EXPORT___;
