var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "loader-section" },
    [
      _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
        _vm.isLoading
          ? _c("div", { key: "1" }, [_c("LoaderCircle")], 1)
          : _c(
              "div",
              { key: "2", staticClass: "w-100 h-100" },
              [_vm._t("default")],
              2
            ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }