var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "s-header__rating" }, [
    _c("div", { staticClass: "rating__row" }, [
      _c("span", { staticClass: "rating__key" }, [
        _vm._v(
          "\n      " +
            _vm._s(_vm.$t("components.common.support.rating")) +
            ":\n    "
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "rating__value" }, [
        _vm._v(_vm._s(_vm.rating.rating)),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "rating__row" }, [
      _c("span", { staticClass: "rating__key" }, [
        _vm._v(
          "\n      " +
            _vm._s(_vm.$t("components.common.support.updated")) +
            ":\n    "
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "rating__value" }, [
        _vm._v(_vm._s(_vm.getDateFormatted(_vm.rating.date))),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "rating__row rating__row--list" }, [
      _c("div", { staticClass: "rating__item" }, [
        _c("span", { staticClass: "rating__key" }, [
          _vm._v(
            "\n        " +
              _vm._s(_vm.$t("components.common.support.min")) +
              ".:\n      "
          ),
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "rating__value" }, [
          _vm._v(_vm._s(_vm.rating.minRating)),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "rating__item" }, [
        _c("span", { staticClass: "rating__key" }, [
          _vm._v(
            "\n        " +
              _vm._s(_vm.$t("components.common.support.mid")) +
              ".:\n      "
          ),
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "rating__value" }, [
          _vm._v(_vm._s(_vm.rating.avgRating)),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "rating__item" }, [
        _c("span", { staticClass: "rating__key" }, [
          _vm._v(
            "\n        " +
              _vm._s(_vm.$t("components.common.support.max")) +
              ".:\n      "
          ),
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "rating__value" }, [
          _vm._v(_vm._s(_vm.rating.maxRating)),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }