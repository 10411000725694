var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "ElModal",
    {
      class: _vm.$style.modal,
      attrs: { "max-width": 600, name: _vm.name },
      on: { opened: _vm.onOpened, closed: _vm.onClosed },
    },
    [
      _vm.isLoading
        ? _c("LoaderCircle")
        : _c(
            "div",
            [
              _c(
                "ElButton",
                {
                  staticClass: "btn-img vm--modal-close",
                  on: { click: _vm.onClickBtnHide },
                },
                [_c("img", { attrs: { src: _vm.icons.close, alt: "close" } })]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "vm--modal-content" }, [
                _c("div", [
                  _c("div", { staticClass: "h3-modal" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.promoCampaign.name) +
                        "\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "mb-3",
                    domProps: {
                      innerHTML: _vm._s(_vm.promoCampaign.description),
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "row mb-3", class: _vm.$style.product },
                    [
                      _c("div", { staticClass: "col-12 col-sm-5" }, [
                        _c("div", { class: _vm.$style.image }, [
                          _c("figure", { staticClass: "figure" }, [
                            _c("img", {
                              staticClass: "figure-img",
                              attrs: { src: _vm.imageSrc, alt: "cover" },
                            }),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-12 col-sm-7" }, [
                        _c("h3", { staticClass: "pt-2" }, [
                          _vm._v(_vm._s(_vm.productName)),
                        ]),
                        _vm._v(" "),
                        _c("div", {
                          domProps: {
                            innerHTML: _vm._s(_vm.productDescription),
                          },
                        }),
                      ]),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "d-flex justify-content-between" },
                  [
                    _c("ElButton", {
                      staticClass: "btn-primary",
                      attrs: { label: _vm.labelBtnGo },
                      on: { click: _vm.onClickBtnGo },
                    }),
                    _vm._v(" "),
                    _c("ElButton", {
                      staticClass: "btn-outline-primary",
                      attrs: { label: "Отмена" },
                      on: { click: _vm.onClickBtnHide },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }