import createState from '@frontend/store/showcase/common/purchase/state'
import Constants from 'Constants'
import { RESET_STATE } from 'Helpers/ResetState'
import { set } from 'lodash'
import Vue from 'vue'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setPurchases(state, payload) {
    state.purchases = payload
  },

  setPurchase(state, payload) {
    state.purchase = payload
  },

  setPurchaseId(state, payload) {
    state.purchaseId = Number(payload)
  },

  setClientData(state, payload) {
    state.customer = payload
  },

  setStudents(state, payload) {
    state.students = payload
  },

  setStudent(state, { studentId, payload }) {
    const index = state.students.findIndex(
      (student) => student.id === studentId,
    )

    Vue.set(state.students, index, payload)
  },

  setStudentValue(state, { index, path, value }) {
    set(state.students[index], path, value)
  },

  setClientValue(state, { path, value }) {
    set(state.customer, path, value)
  },

  setOptionValue(state, { path, value }) {
    set(state.options, path, value)
  },

  setClientDefaultCitizenship(state) {
    state.customer.citizenship = state.options.citizenshipList.find(
      (citizenship) =>
        citizenship.id === Constants.citizenship.RUSSIAN_FEDERATION,
    )
  },

  createClientPassportForCitizenshipRussia(state) {
    state.customer.passport = {
      number: '',
      series: '',
      issueDate: '',
      issuedBy: '',
      departmentCode: '',
    }
  },

  createClientPassportForCitizenshipOther(state) {
    state.customer.passport = {
      country: null,
      number: '',
      issueDate: '',
    }
  },

  createClientAddress(state) {
    state.customer.address = {
      permanent: '',
    }
  },

  setIsClientDataValid(state, payload) {
    state.isClientDataValid = payload
  },

  setIsStudentsDataValid(state, payload) {
    state.isStudentsDataValid = payload
  },

  setClientDocuments(state, payload) {
    state.purchase.clientDocuments = payload
  },

  setInvoices(state, payload) {
    state.invoices = payload
  },

  setPurchaseAgreementValue(state, { index, key, value }) {
    Vue.set(state.purchase.agreements[index], key, value)
  },
}
