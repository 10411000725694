import ApiBase from 'ApiRest/ApiBase'

export const ApiShowcasePurchaseStudents = new (class extends ApiBase {
  /**
   * Получение данных студентов в заказе
   *
   * @param {number} purchaseId
   * @returns {Promise}
   */
  get(purchaseId) {
    const url = `/purchase/${purchaseId}/students`

    return this._GET(url)
  }
})()
