import ApiBase from 'ApiRest/ApiBase'

export const ApiShowcasePurchaseItemCertificate = new (class extends ApiBase {
  /**
   * Сертификат купленного продукта
   *
   * @param {Object} params
   * @param {number} params.purchaseId
   * @param {number} params.purchaseItemId
   * @returns {Promise}
   */
  get({ purchaseId, purchaseItemId }) {
    const url = `/purchase/${purchaseId}/item/${purchaseItemId}/certificate`

    const config = {
      responseType: 'blob',
    }

    return this._GET(url, config)
  }
})()
