var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a",
    { attrs: { href: _vm.href, target: _vm.targetLocal, rel: _vm.relLocal } },
    [
      _vm._t("default", function () {
        return [_vm._v("\n    " + _vm._s(_vm.label) + "\n  ")]
      }),
      _vm._v(" "),
      _vm.isRendered.iconExternal
        ? _c("img", {
            staticClass: "ml-1",
            attrs: { src: _vm.icons.linkExternal, alt: "external link" },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }